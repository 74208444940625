import { CSSProperties, FC } from 'react';
import { Select } from 'antd';

import {
  LanguageList,
  LanguageListRequest,
  PlainLanguageCode,
  SelectItem,
  useAPIRequest,
} from '../../api';

export const LanguageSelect: FC<{
  value?: string;
  onChange?: (
    value: string,
    option: SelectItem<PlainLanguageCode> | SelectItem<PlainLanguageCode>[],
  ) => void;
  style?: CSSProperties;
}> = ({ style, value, onChange, ...rest }) => {
  const { response, isLoading } =
    useAPIRequest<LanguageList>(LanguageListRequest);

  return (
    <Select
      placeholder="Select a language..."
      loading={isLoading}
      options={response?.data}
      defaultValue={response?.data?.[0]?.value}
      value={value}
      onChange={onChange}
      style={style}
      {...rest}
    />
  );
};
