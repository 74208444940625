import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { persist } from 'zustand/middleware';

import { ChatCharacter } from '../api';

interface PersistedStoreState {
  chatVoiceActive: boolean;
  chatCharacter?: ChatCharacter;
  defaultPath: string | undefined;
  setDefaultPath: (value: string | undefined) => void;
  setChatVoiceActive: (value: boolean) => void;
  setChatCharacter: (value?: ChatCharacter) => void;
}

export const persistedStore = createVanilla<PersistedStoreState>(
  persist<PersistedStoreState>(
    (set) => ({
      chatVoiceActive: true,
      defaultPath: '/',
      chatCharacter: undefined,
      setDefaultPath: (value: string | undefined) => {
        set({ defaultPath: value });
      },
      setChatVoiceActive: (value: boolean) => {
        set({ chatVoiceActive: value });
      },
      setChatCharacter: (value?: ChatCharacter) => {
        set({ chatCharacter: value });
      },
    }),
    {
      name: 'persisted',
    },
  ),
);

export const usePersistedStore = create<PersistedStoreState>(persistedStore);
