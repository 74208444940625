import { Table, Typography } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';

import { OverviewMediaParams, UserType } from '../../../api';
import { capitalizeFirstLetter, formatCurrency } from '../../../utils';
import { useInitStore } from '../../../stores';

const { Title } = Typography;

export const MediaOverview: FC<{
  data?: OverviewMediaParams[];
  includeCharacter?: boolean;
  style?: React.CSSProperties;
}> = ({ data, includeCharacter, style }) => {
  const user = useInitStore((state) => state.user);

  let columns: ColumnsType<OverviewMediaParams> = [];

  if (includeCharacter) {
    columns.push({
      title: 'Character',
      dataIndex: 'character',
      key: 'character',
      render: (_: any, item: OverviewMediaParams) => {
        return (
          <Link to={`/character/${item.character.id}`}>
            {item.character.name}
          </Link>
        );
      },
    });
  }

  columns = columns.concat([
    {
      title: 'Media',
      dataIndex: 'media',
      key: 'media',
      render: (_: any, item: OverviewMediaParams) => {
        return (
          <Link to={`/character/${item.character.id}/media/${item.media.id}`}>
            <img
              src={item.media.thumbnailUrl}
              style={{
                width: '60px',
                height: '60px',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
          </Link>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, item: OverviewMediaParams) => {
        return capitalizeFirstLetter(item.media.type);
      },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
    },
  ]);

  if (user?.type === UserType.Admin) {
    columns.push({
      title: 'Gross Amount',
      dataIndex: 'grossAmount',
      key: 'grossAmount',
      render: (grossAmount: number) => {
        return <span>{formatCurrency(grossAmount)}</span>;
      },
    });
  }

  columns.push({
    title: user?.type === UserType.Admin ? 'Net Amount' : 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => {
      return <span>{formatCurrency(amount)}</span>;
    },
  });

  return (
    <Table
      title={() => <Title level={3}>Media Purchases</Title>}
      dataSource={data}
      columns={columns}
      rowKey={(row) => row.media.id}
      style={style}
    />
  );
};
