import { Typography } from 'antd';

import { OverviewBreakdown, CenteredContainer, If } from '../../../common';
import {
  BillingPeriod,
  BillingOverviewRequest,
  MediaUnpublishedRequest,
  UserType,
  useAPIRequest,
} from '../../../api';
import { useInitStore } from '../../../stores';

const { Title } = Typography;

const Overview: React.FC = () => {
  const user = useInitStore((state) => state.user);

  const { response } = useAPIRequest<{
    total: number;
  }>(MediaUnpublishedRequest, {
    immediate: [UserType.Admin, UserType.ContentManager].includes(user!.type),
  });

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Title
        style={{
          marginTop: '40px',
          marginBottom: '20px',
        }}
      >
        Overview
      </Title>

      <If condition={(response?.total || 0) > 0}>
        <div style={{ color: 'red', marginBottom: '20px' }}>
          Unpublished Media: {response?.total}
        </div>
      </If>

      <OverviewBreakdown
        configFactory={(period: BillingPeriod) =>
          BillingOverviewRequest(period)
        }
        includeCharacter
      />
    </CenteredContainer>
  );
};

export default Overview;
