import create from 'zustand';

interface MiscStoreState {
  siderCollapsed: boolean;
  siderWidth: string;
  setSiderCollapsed: (value: boolean) => void;
}

export const useMiscStore = create<MiscStoreState>((set) => ({
  siderCollapsed: false,
  siderWidth: '300px',
  setSiderCollapsed: (value: boolean) => set({ siderCollapsed: value }),
}));
