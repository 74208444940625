import { RouteData } from '../../../utils';
import Character from './character/character.component';
import Characters from './characters/characters.component';
import Chats from './chat/chats.component';
import FineTune from './fine-tune/fine-tune.component';
import Media from './media/media.component';
import Medias from './medias/medias.component';
import CharacterOverview from './overview/character-overview.component';

export const CharacterAdminRoutes: RouteData[] = [
  {
    path: 'characters',
    component: Characters,
    authRequired: true,
  },
  {
    path: 'character/:characterId',
    component: Character,
    authRequired: true,
  },
  {
    path: 'character/:characterId/media',
    component: Medias,
    authRequired: true,
  },
  {
    path: 'character/:characterId/media/:mediaId',
    component: Media,
    authRequired: true,
  },
  {
    path: 'character/:characterId/fine-tune',
    component: FineTune,
    authRequired: true,
  },
  {
    path: 'character/:characterId/chat',
    component: Chats,
    authRequired: true,
  },
  {
    path: 'character/:characterId/overview',
    component: CharacterOverview,
    authRequired: true,
  },
];
