import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Logo: FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <Link
      to="/"
      style={{
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <img src="/logo192.png" style={{ width: '32px', marginRight: '10px' }} />
      AnimeGF
    </Link>
  );
};
