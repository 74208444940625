import { Menu, MenuProps } from 'antd';
import {
  UserOutlined,
  DollarOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Logo } from './logo.component';
import { useInitStore } from '../../stores';
import { UserType } from '../../api';
import { ItemType } from 'antd/es/menu/hooks/useItems';

enum MenuOptionKey {
  Profile = 'profile',
  Account = 'account',
  Character = 'characters',
  Overview = 'overview',
  Payouts = 'payouts',
  Keys = 'keys',
}

// the first route in the array is where the link will navigate to
const MenuOptionKeyRoutes: {
  [key: string]: string | string[];
} = {
  [MenuOptionKey.Profile]: '/settings/profile',
  [MenuOptionKey.Account]: '/settings/account',
  [MenuOptionKey.Overview]: '/overview',
  [MenuOptionKey.Payouts]: '/payouts',
  [MenuOptionKey.Keys]: '/keys',
  [MenuOptionKey.Character]: ['/characters', '/character'],
};

function getOptionKeyByRoute(path: string) {
  for (const [key, keyRoutes] of Object.entries(MenuOptionKeyRoutes)) {
    if (Array.isArray(keyRoutes)) {
      if (keyRoutes.some((keyRoute) => path.includes(keyRoute))) return key;
    } else if (path.includes(keyRoutes)) return key;
  }

  return undefined;
}

type MenuItem = ItemType & { userType?: UserType | UserType[] };

export const AppMenu: FC<{ closeDrawer?: () => void }> = ({ closeDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useInitStore((state) => state.user);

  const items: MenuItem[] = [
    {
      label: 'Overview',
      icon: <LineChartOutlined className="account-menu-option-icon" />,
      key: MenuOptionKey.Overview,
      userType: [UserType.Creator, UserType.Admin],
    },
    {
      label: 'Characters',
      icon: <UserOutlined className="account-menu-option-icon" />,
      key: MenuOptionKey.Character,
      userType: [UserType.Creator, UserType.Admin, UserType.ContentManager],
    },
    {
      label: 'Payouts',
      icon: <DollarOutlined className="account-menu-option-icon" />,
      key: MenuOptionKey.Payouts,
      userType: [UserType.Creator, UserType.Admin],
    },
    {
      label: 'Keys',
      icon: <UserOutlined className="account-menu-option-icon" />,
      key: MenuOptionKey.Keys,
      userType: UserType.Admin,
    },
  ];

  let filteredItems: MenuItem[] = [];

  if (user) {
    filteredItems = items
      .filter((item) => {
        if (!item.userType) return true;

        let userTypes: UserType[] = [];

        if (!Array.isArray(item.userType)) userTypes = [item.userType];
        else userTypes = item.userType;

        return userTypes.includes(user.type);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ userType, ...item }) => item); // userType shouldn't be passed to the Menu component
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const keyRoutes = MenuOptionKeyRoutes[e.key];

    if (keyRoutes) {
      navigate(Array.isArray(keyRoutes) ? keyRoutes[0] : keyRoutes);
    } else {
      /*if (e.key === MenuOptionKey.Documentation) {
        window.open(process.env.REACT_APP_DOCS, '_blank');
      }*/
    }

    closeDrawer?.();
  };

  const selectedKey = getOptionKeyByRoute(location.pathname);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Logo />

      <Menu
        mode="inline"
        items={filteredItems}
        onClick={handleMenuClick}
        selectedKeys={selectedKey ? [selectedKey] : undefined}
        style={{ flex: 1 }}
      />
    </div>
  );
};
