import { Layout, theme } from 'antd';
import { FC } from 'react';

import { useMiscStore } from '../../stores';
import { AppMenu } from './app-menu.component';

const { Sider } = Layout;

export const AppSider: FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const siderWidth = useMiscStore((state) => state.siderWidth);
  const siderCollapsed = useMiscStore((state) => state.siderCollapsed);
  const setSiderCollapsed = useMiscStore((state) => state.setSiderCollapsed);

  return (
    <>
      {!siderCollapsed && <div style={{ width: siderWidth }} />}

      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        style={{
          background: colorBgContainer,
          height: '100vh',
          position: 'fixed',
        }}
        width={siderWidth}
        onCollapse={setSiderCollapsed}
      >
        <AppMenu />
      </Sider>
    </>
  );
};
