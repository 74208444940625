import { Avatar, Dropdown, MenuProps } from 'antd';
import {
  LogoutOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import AccountInfo from './account-info.component';
import { useInitStore, useAuthStore } from '../../../../stores';

import './account-menu.component.css';

enum MenuOptionKey {
  SignOut = 'signOut',
  Profile = 'profile',
  Account = 'account',
  TermsOfService = 'termsOfService',
  PrivacyPolicy = 'privacyPolicy',
}

const AccountMenu: FC = () => {
  const navigate = useNavigate();

  const resetInitData = useInitStore((state) => state.resetInitData);
  const profile = useInitStore((state) => state.user);

  const clearCredentials = useAuthStore((state) => state.clearCredentials);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key as MenuOptionKey) {
      case MenuOptionKey.SignOut:
        resetInitData();
        clearCredentials();
        break;
      case MenuOptionKey.Profile:
        navigate('/settings/profile');
        break;
      case MenuOptionKey.Account:
        navigate('/settings/account');
        break;
      case MenuOptionKey.TermsOfService:
        navigate('/tos');
        break;
      case MenuOptionKey.PrivacyPolicy:
        navigate('/privacy');
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: <AccountInfo data={profile} />,
      key: MenuOptionKey.Profile,
    },
    { type: 'divider' },
    {
      label: 'Account',
      icon: <SettingOutlined className="account-menu-option-icon" />,
      key: MenuOptionKey.Account,
    },
    { type: 'divider' },
    {
      label: 'Sign out',
      icon: <LogoutOutlined className="account-menu-option-icon" />,
      key: MenuOptionKey.SignOut,
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      trigger={['click']}
    >
      <Avatar
        src={profile?.avatarUrl}
        icon={!profile?.avatarUrl && <UserOutlined />}
        className="account-menu-avatar"
      />
    </Dropdown>
  );
};

export default AccountMenu;
