import { Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { CenteredContainer, EmailForm } from '../../../common';
import { useInitStore, usePersistedStore } from '../../../stores';

import '../../auth/auth-form.component.css';

const { Text, Title } = Typography;

const ConfirmEmail: React.FC = () => {
  const user = useInitStore((state) => state.user);
  const navigate = useNavigate();
  const defaultPath = usePersistedStore((state) => state.defaultPath);

  if (user?.emailConfirmed && defaultPath) navigate(defaultPath);

  return (
    <CenteredContainer className="auth-form" vertical>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Title style={{ textAlign: 'center', fontSize: '28px' }}>
          Confirm your email address
        </Title>

        <Text>
          Confirmation email sent to {user?.email}. Please check your inbox.
        </Text>

        <EmailForm />
      </Space>
    </CenteredContainer>
  );
};

export default ConfirmEmail;
