import { Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';

import { AppMenu } from '../../app-menu.component';

import './app-drawer.component.css';

export const AppDrawer: FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={style}>
      <Button
        icon={<MenuOutlined />}
        style={{ border: 0 }}
        onClick={() => setOpen(true)}
      />

      <Drawer
        placement="left"
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
        width="300px"
        style={{ padding: 0 }}
      >
        <AppMenu closeDrawer={() => setOpen(false)} />
      </Drawer>
    </div>
  );
};
