import { Button, Form, Input, Space, Typography } from 'antd';

import {
  FineTuneExampleParams,
  formRequestErrorHandler,
  getFineTuneExampleRequests,
  useRequests,
} from '../../../../api';
import { If } from '../../../../common';
import { confirmDangerousAction } from '../../../../utils';

const { Title } = Typography;
const { TextArea } = Input;

const FineTuneExample: React.FC<{
  characterId: string;
  data?: Partial<FineTuneExampleParams>;
  index?: number;
  onSuccess?: (data: FineTuneExampleParams, key: string) => void;
}> = ({ characterId, data, index, onSuccess }) => {
  const [form] = Form.useForm();

  const { request, isLoading } = useRequests({
    requests: getFineTuneExampleRequests(characterId),
    callbacks: {
      onSuccess,
      onError: formRequestErrorHandler(form),
    },
  });

  const onRequest = (params: Omit<FineTuneExampleParams, 'id'>) => {
    if (data?.id) request.update(data?.id, params);
    else request.create(params);
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        borderRadius: '8px',
        border: 'solid 1px #cccccc',
        padding: '20px',
      }}
    >
      <Form
        form={form}
        onFinish={onRequest}
        initialValues={data}
        layout="vertical"
        requiredMark={false}
      >
        <Title level={3}>{index ? `Example #${index}` : 'New Example'}</Title>

        <Form.Item
          name="prompt"
          label="Prompt"
          rules={[{ required: true, message: 'Prompt is required' }]}
        >
          <TextArea
            autoSize={{ minRows: 1, maxRows: 6 }}
            style={{ width: '800px' }}
          />
        </Form.Item>

        <Form.Item
          name="response"
          label="Response"
          rules={[{ required: true, message: 'Response is required' }]}
        >
          <TextArea
            autoSize={{ minRows: 1, maxRows: 6 }}
            style={{ width: '800px' }}
          />
        </Form.Item>

        <If condition={data?.id}>
          <Space direction="horizontal">
            <Button disabled={isLoading} onClick={form.submit} type="primary">
              Update
            </Button>

            <Button
              disabled={isLoading}
              onClickCapture={() =>
                confirmDangerousAction({
                  action: 'Delete',
                  name: 'this example',
                  onConfirm: () => request.delete(data?.id),
                })
              }
              type="primary"
              danger
            >
              Delete
            </Button>
          </Space>
        </If>

        <If condition={!data?.id}>
          <Button disabled={isLoading} onClick={form.submit} type="primary">
            Add
          </Button>
        </If>
      </Form>
    </Space>
  );
};

export default FineTuneExample;
