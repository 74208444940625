import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { CreateKeyRequest } from './create-key.request';
import { DeleteKeyRequest } from './delete-key.request';
import { KeyParams } from './key-params.interface';

import { ReadKeyRequest } from './read-key.request';
import { UpdateKeyRequest } from './update-key.request';

export function getKeyRequests(): Requests<KeyParams> {
  return {
    [CRUDKey.Create]: CreateKeyRequest,
    [CRUDKey.Read]: ReadKeyRequest,
    [CRUDKey.Update]: UpdateKeyRequest,
    [CRUDKey.Delete]: DeleteKeyRequest,
  };
}
