import { FC } from 'react';
import { Form, Input, Button, Typography, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  formRequestErrorHandler,
  ResetPasswordRequest,
  useAPIRequest,
} from '../../../api';
import { CenteredContainer, StatefulLink } from '../../../common';
import { createNotification, NotificationType } from '../../../utils';

import '../auth-form.component.css';

const { Text, Title } = Typography;

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { request, isLoading } = useAPIRequest<{
    success: boolean;
  }>((params: { email: string }) => ResetPasswordRequest(params.email), {
    immediate: false,
    onSuccess: (data, params: { email: string }) => {
      createNotification({
        key: 'resetPassword',
        message: `Password reset instructions sent to ${params?.email}. Please check your inbox.`,
        type: NotificationType.Success,
        duration: 6,
      });

      navigate('/signin');
    },
    onError: formRequestErrorHandler(form),
  });

  return (
    <CenteredContainer className="auth-form" vertical>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Title style={{ textAlign: 'center', fontSize: '28px' }}>
          Forgot your password?
        </Title>

        <StatefulLink
          to="/signin"
          disabled={isLoading}
          className="auth-form-link"
        >
          <span style={{ color: '#676767' }}>Remembered your password?</span>{' '}
          Sign in
        </StatefulLink>

        <Form
          form={form}
          onFinish={request}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          disabled={isLoading}
          className="auth-form-inner"
        >
          <div style={{ paddingBottom: '20px' }}>
            <Text>
              Fill in the email below and we'll send you password reset
              instructions!
            </Text>
          </div>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Email must not be empty',
              },
              {
                type: 'email',
                message: 'Email is invalid',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              className="auth-form-input"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="auth-form-button"
              loading={isLoading}
            >
              Reset password
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </CenteredContainer>
  );
};

export default ForgotPassword;
