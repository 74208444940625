import { AxiosRequestConfig } from 'axios';

import { FineTuneExampleParams } from './fine-tune-example-params.interface';

export const UpdateFineTuneExampleRequest = (
  id: string,
  data: Omit<FineTuneExampleParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/fine-tune/example/${id}`,
    method: 'put',
    data,
  };
};
