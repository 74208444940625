import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AsyncPipelineItem,
  AsyncPipelineLoader,
  CenteredContainer,
} from '../../../common';
import { useAuthStore, useInitStore } from '../../../stores';
import { apiRequest, ReadUserRequest } from '../../../api';

const Init: FC = () => {
  const navigate = useNavigate();

  const setProfile = useInitStore((state) => state.setUser);
  const setInitialized = useInitStore((state) => state.setInitialized);

  const resetInitData = useInitStore((state) => state.resetInitData);
  const clearCredentials = useAuthStore((state) => state.clearCredentials);

  const pipeline: AsyncPipelineItem[] = [
    {
      key: 'profile',
      factory: () => apiRequest(ReadUserRequest()),
      onSuccess: setProfile,
    },
  ];

  return (
    <CenteredContainer vertical>
      <AsyncPipelineLoader
        pipeline={pipeline}
        options={{
          onSuccess: setInitialized,
          onError: (e) => {
            if (e.message === 'User not found') {
              resetInitData();
              clearCredentials();

              navigate('/');
            }
          },
        }}
      />
    </CenteredContainer>
  );
};

export default Init;
