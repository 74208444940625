import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, Space } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import {
  AuthResponse,
  formRequestErrorHandler,
  useAPIRequest,
  ChangePasswordRequest,
} from '../../../api';
import { useAuthStore, useInitStore } from '../../../stores';
import { CenteredContainer } from '../../../common';
import { createNotification, NotificationType } from '../../../utils';

import '../auth-form.component.css';

const { Text, Title } = Typography;

const ChangePassword: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const setCredentials = useAuthStore((state) => state.setCredentials);

  const queryValues = useInitStore((state) => state.queryValues);

  const forwardedToken = useInitStore((state) => state.forwardedToken);

  useEffect(() => {
    if (queryValues) {
      if (!forwardedToken) navigate('/');
    }
  }, [queryValues, forwardedToken]);

  const { request, isLoading } = useAPIRequest<AuthResponse>(
    (params: { password: string; confirmPassword: string }) =>
      ChangePasswordRequest({
        token: forwardedToken as string,
        password: params.password,
      }),
    {
      immediate: false,
      onSuccess: (response: AuthResponse) => {
        setCredentials(response);

        createNotification({
          key: 'changePassword',
          message: 'Password has been changed successfully.',
          type: NotificationType.Success,
          duration: 6,
        });
      },
      onError: formRequestErrorHandler(form, (error) => {
        if (!error.fields) navigate('/forgot-password');
      }),
    },
  );

  return (
    <CenteredContainer className="auth-form" vertical>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Title style={{ textAlign: 'center', fontSize: '28px' }}>
          Change password
        </Title>

        <div style={{ marginBottom: '1em' }}>
          <Text>Please enter a new password below...</Text>
        </div>

        <Form
          form={form}
          onFinish={request}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          disabled={isLoading}
          className="auth-form-inner"
        >
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Password must not be empty' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              className="auth-form-input"
            />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            rules={[
              { required: true, message: 'Confirm password must not be empty' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value)
                    return Promise.resolve();

                  return Promise.reject(new Error("Passwords don't match"));
                },
              }),
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm password"
              className="auth-form-input"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="auth-form-button"
              loading={isLoading}
            >
              Change password
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </CenteredContainer>
  );
};

export default ChangePassword;
