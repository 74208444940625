import { CRUDKey } from '../../../common';
import { ImmediateRequest, Requests } from '../../hooks';
import { ReadUserRequest } from './read-user.request';
import { UpdateUserRequest } from './update-user-details.request';
import { UserParams } from './user-params.interface';

export function getUserRequests(): Requests<UserParams> {
  return {
    [CRUDKey.Read]: ImmediateRequest(() => ReadUserRequest()),
    [CRUDKey.Update]: (params) => UpdateUserRequest(params),
  };
}
