import { AxiosRequestConfig } from 'axios';

import { FineTuneExampleParams } from './fine-tune-example-params.interface';

export const CreateFineTuneExampleRequest = (
  characterId: string,
  data: Omit<FineTuneExampleParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/fine-tune/example`,
    method: 'post',
    data: { ...data, characterId },
  };
};
