import { Button, Space } from 'antd';

import {
  useRequests,
  getFineTuneRequests,
  FineTunedModelResponse,
} from '../../../../api';
import { If, Loader } from '../../../../common';
import { confirmDangerousAction } from '../../../../utils';

const FineTunedModel: React.FC<{ characterId: string }> = ({ characterId }) => {
  const { request, response, error, isLoading } = useRequests({
    requests: getFineTuneRequests(characterId),
    callbacks: {
      onSuccess: (data: FineTunedModelResponse): void => {
        const isRunning = data?.latest && data.latest.progress !== 100;

        console.log(isRunning, data);

        if (isRunning) setTimeout(request.read, 5 * 1000);
      },
    },
  });

  const isRunning = response?.latest && response.latest.progress !== 100;

  return (
    <Loader error={error} isLoading={isLoading && !response}>
      <If condition={isRunning}>
        Fine Tuning: {response?.latest?.progress}% ({response?.latest?.status})
      </If>

      <If condition={!isRunning}>
        <Space direction="vertical">
          <Button
            disabled={isLoading}
            onClick={() =>
              confirmDangerousAction({
                action: 'Create',
                name: 'a new fine tuning job',
                onConfirm: request.create,
              })
            }
            type="primary"
          >
            Fine Tune
          </Button>

          <If condition={response?.stable}>
            Fine Tuned Model: {response?.stable?.model}
          </If>

          <If condition={response?.latest}>
            Last Fine Tuning: {response?.latest?.created} (
            {response?.latest?.status})
          </If>
        </Space>
      </If>
    </Loader>
  );
};

export default FineTunedModel;
