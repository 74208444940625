import { FC, useState } from 'react';
import { SoundOutlined } from '@ant-design/icons';

import {
  PlainLanguageCode,
  TestVoiceRequest,
  VoiceOptions,
  requestErrorHandler,
} from '../../../../api';
import { RequestButton } from '../../../../common';
import { useBufferedVoice } from './use-buffered-voice.hook';

export const TestVoiceButton: FC<{
  name: string;
  language: PlainLanguageCode;
  options: VoiceOptions;
  disabled?: boolean;
}> = ({ name, language, options, disabled }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const playVoice = useBufferedVoice();

  return (
    <RequestButton
      disabled={disabled || isPlaying}
      size="large"
      icon={<SoundOutlined />}
      configFactory={() => TestVoiceRequest(name, language, options)}
      onSuccess={async (response: string) => {
        setIsPlaying(true);

        playVoice(response);

        setIsPlaying(false);
      }}
      onError={requestErrorHandler()}
    >
      Listen
    </RequestButton>
  );
};
