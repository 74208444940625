import { AxiosRequestConfig } from 'axios';

import { KeyParams } from './key-params.interface';

export const UpdateKeyRequest = (
  id: string,
  data: Omit<KeyParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/key/${id}`,
    method: 'put',
    data,
  };
};
