import { Avatar, Space } from 'antd';
import { QuestionOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { MediaParams, MediaType, UserType } from '../../../../api';
import { If } from '../../../../common';
import MediaItemMssing from './media-item-missing.component';
import { useInitStore } from '../../../../stores';
import MediaItemNote from './media-item-note.component';

const MediaItem: React.FC<{ characterId: string; data: MediaParams }> = ({
  characterId,
  data,
}) => {
  const user = useInitStore((state) => state.user);

  return (
    <Link
      to={`/character/${characterId!}/media/${data.id}`}
      key={data.id}
      style={{ maxWidth: '100%' }}
    >
      <div style={{ position: 'relative' }}>
        <Avatar
          shape="square"
          size={160}
          src={data.thumbnailUrl}
          icon={<QuestionOutlined />}
        />

        <If condition={data.type === MediaType.Video}>
          <div
            style={{
              position: 'absolute',
              top: '5%',
              right: '5%',
            }}
          >
            <VideoCameraOutlined style={{ fontSize: '20px', color: 'white' }} />
          </div>
        </If>

        <If
          condition={
            user?.type === UserType.Admin ||
            user?.type === UserType.ContentManager
          }
        >
          <Space
            direction="vertical"
            style={{
              position: 'absolute',
              bottom: '5%',
              left: '5%',
            }}
          >
            <If
              condition={
                !data.description && (!data.tags || data.tags.length === 0)
              }
            >
              <MediaItemMssing text="No Description / Tags" />
            </If>

            <If condition={!data.published}>
              <MediaItemMssing text="Unpublished" />
            </If>

            <If condition={data.missingIndex}>
              <MediaItemMssing text="Missing Index" />
            </If>

            <If condition={data.welcome}>
              <MediaItemNote text="Welcome" />
            </If>
          </Space>
        </If>
      </div>
    </Link>
  );
};

export default MediaItem;
