import { Outlet } from 'react-router-dom';

const SettingsContainer: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default SettingsContainer;
