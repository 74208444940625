import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import Init from '../routes/app/init/init.component';
import { useAuthStore, useInitStore } from '../stores';

export function useLayoutChildren(children?: ReactNode | undefined) {
  const initialized = useInitStore((state) => state.initialized);
  const user = useInitStore((state) => state.user);
  const token = useAuthStore((state) => state.token);

  if (initialized) {
    if (!user?.emailConfirmed) {
      return <Navigate to="/confirm-email" replace />;
    } else if (user?.name) return children;
    else return <Navigate to="/onboarding" replace />;
  }

  return token ? <Init /> : children;
}

// currently rendering a "required" route, e.g. confirm-email. used in PersistDefaultPath component to not reset defaultPath when rendering required routes
export function useLayoutRequired() {
  const initialized = useInitStore((state) => state.initialized);
  const user = useInitStore((state) => state.user);

  return !initialized || (initialized && (user?.emailConfirmed || !user?.name));
}
