import { Col, Layout } from 'antd';
import { FC } from 'react';

import { useLayoutDisplay } from '../../common';
import { Logo } from '../app/logo.component';

const { Header } = Layout;

const AuthHeader: FC<{
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({ style, ...props }) => {
  const { headerWidth, breakpoint } = useLayoutDisplay();

  return (
    <Header
      style={{
        border: 0,
        width: headerWidth,
        paddingInline: breakpoint.xs ? '20px' : '50px',
        ...style,
      }}
      {...props}
    >
      <Logo />

      <Col flex={1} />

      {/*<div>Support Button</div>*/}
    </Header>
  );
};

export default AuthHeader;
