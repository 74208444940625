import { Navigate } from 'react-router-dom';

import { useInitStore } from '../../../stores';
import { UserType } from '../../../api';

const PrivateIndex: React.FC = () => {
  const user = useInitStore((state) => state.user);

  if (user) {
    if ([UserType.Creator, UserType.Admin].includes(user.type)) {
      return <Navigate to="/overview" replace />;
    } else if (user.type === UserType.ContentManager) {
      return <Navigate to="/characters" replace />;
    }
  }

  return <></>;

  /*return (
    <CenteredContainer
      style={{
        width: '80%',
        height: '100%',
        marginTop: '80px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      Dashboard
    </CenteredContainer>
  );*/
};

export default PrivateIndex;
