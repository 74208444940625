import { RouteData } from '../../../utils';
import Overview from './overview.component';

export const OverviewRoutes: RouteData[] = [
  {
    path: 'overview',
    component: Overview,
    authRequired: true,
  },
];
