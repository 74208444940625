import { FC } from 'react';

import { SendConfirmEmailRequest } from '../../api';
import { useInitStore } from '../../stores';
import { createNotification, NotificationType } from '../../utils';
import { RequestButton } from './request-button.component';

export const SendConfirmationEmailButton: FC<{
  disabled?: boolean;
  style?: React.CSSProperties;
}> = ({ disabled, style }) => {
  const user = useInitStore((state) => state.user);

  return (
    <RequestButton
      type="primary"
      configFactory={SendConfirmEmailRequest}
      onSuccess={() => {
        createNotification({
          key: 'confirmEmail',
          message: `Confirmation email sent to ${user?.email}. Please check your inbox.`,
          type: NotificationType.Success,
          duration: 6,
        });
      }}
      disabled={disabled}
      style={style}
    >
      Resend confirmation email
    </RequestButton>
  );
};
