import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CharacterParams,
  useAPIRequest,
  formRequestErrorHandler,
  CreateCharacterRequest,
} from '../../../../api';
import {
  createNotification,
  NotificationType,
  RouteData,
} from '../../../../utils';
import HandleFormItem from '../handle-form-item.component';

const { Title } = Typography;

const CreateCharacter: React.FC<{
  rawRoutes?: { [key: string]: RouteData[] };
  style?: React.CSSProperties;
}> = ({ rawRoutes, style }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { request, response, isLoading } = useAPIRequest<CharacterParams>(
    (data) => CreateCharacterRequest(data),
    {
      immediate: false,
      onSuccess: (response) => {
        createNotification({
          key: 'characterCreated',
          message: 'Character created successfully.',
          type: NotificationType.Success,
          duration: 6,
        });

        setOpen(false);

        navigate(`/character/${response.id}`);
      },
      onError: formRequestErrorHandler(form),
    },
  );

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setOpen(true)}
        size="middle"
        style={style}
      >
        New Character
      </Button>

      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        closable={!isLoading}
        maskClosable={false}
        width="80%"
        style={{ maxWidth: '500px' }}
      >
        <Title style={{ textAlign: 'center', marginBottom: '20px' }}>
          New Character
        </Title>

        <Form
          form={form}
          onFinish={request}
          initialValues={response}
          layout="vertical"
          requiredMark={false}
          disabled={isLoading}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Name is required' },
              {
                max: 30,
                message: 'Name must be maximum 30 characters',
              },
            ]}
          >
            <Input placeholder="Ex: Jane Doe" style={{ maxWidth: '500px' }} />
          </Form.Item>

          <HandleFormItem rawRoutes={rawRoutes} maxWidth={500} />

          <Space>
            <Button disabled={isLoading} onClick={() => setOpen(false)}>
              Cancel
            </Button>

            <Button disabled={isLoading} onClick={form.submit} type="primary">
              Create
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCharacter;
