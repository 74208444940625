import { useEffect, useRef } from 'react';
import Icon, { PlayCircleFilled } from '@ant-design/icons';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';

import { If } from '../../../../../common';
import { ParsedMessageType } from './parsed-message-type.enum';
import { ParsedMessage } from './parsed-message.interface';
import {
  MessageSpinnerSvg,
  PauseSvg,
  PlaySvg,
} from './message-box-svg.component';
import { MediaType } from '../../../../../api';
import { capitalizeFirstLetter } from '../../../../../utils';

import 'react-h5-audio-player/lib/styles.css';

import './message-box.component.css';

const MessageBox: React.FC<{
  data: ParsedMessage;
  unlockMessageMedia?: (data: ParsedMessage) => any;
  viewMedia?: (data: ParsedMessage) => any;
}> = ({ data, unlockMessageMedia, viewMedia }) => {
  const className: string[] = ['message-box', data.position];
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }
  }, [videoRef]);

  /*const getTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return hours + ':' + minutes;
  };*/

  const isTextOrVoice =
    data.type === ParsedMessageType.Text ||
    data.type === ParsedMessageType.Voice;

  if (isTextOrVoice) className.push('text-or-voice');

  if (data.type === ParsedMessageType.Voice) className.push('voice');
  else className.push('non-voice');

  return (
    <div className={className.join(' ')} key={data.key}>
      <If condition={!!data.value}>
        <If condition={data.type === ParsedMessageType.Text}>{data.value}</If>

        <If condition={data.type === ParsedMessageType.Voice}>
          <AudioPlayer
            src={data.value}
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
            showJumpControls={false}
            layout="horizontal-reverse"
            customIcons={{
              play: (
                <div className="icon-wrapper">
                  <Icon component={PlaySvg} />
                </div>
              ),
              pause: (
                <div className="icon-wrapper">
                  <Icon component={PauseSvg} />
                </div>
              ),
            }}
            customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION]}
          />
        </If>
      </If>

      <If condition={data.media}>
        <If condition={data.media?.unlocked}>
          <div className="pointer" onClick={() => viewMedia?.(data)}>
            <If condition={data.media?.type === MediaType.Video}>
              <PlayCircleFilled
                className="center icon"
                style={{ fontSize: '50px' }}
              />

              <div className="top-right unlock duration">
                {data?.media?.duration}
              </div>
            </If>

            <div
              style={{ backgroundImage: `url('${data.media?.previewUrl}')` }}
              className="media"
            />
          </div>
        </If>

        <If condition={!data.media?.unlocked}>
          <div className="pointer" onClick={() => unlockMessageMedia?.(data)}>
            <div
              style={{ backgroundImage: `url('${data.media?.previewUrl}')` }}
              className="media"
            />

            <div className="center unlock price">${data.media?.price}</div>

            <div className="bottom-center unlock instructions">
              Unlock {capitalizeFirstLetter(data.media?.type || '')}
            </div>

            <If condition={data.media?.type === MediaType.Video}>
              <div className="top-right unlock duration">
                {data?.media?.duration}
              </div>
            </If>
          </div>
        </If>
      </If>

      <If condition={data.loading}>
        <Icon
          component={MessageSpinnerSvg}
          style={{ marginLeft: '10px', marginRight: '10px' }}
        />
      </If>

      {/*<label className="time">{getTime(data.sent)}</label>*/}

      <If condition={isTextOrVoice}>
        <div className="notch" />
      </If>
    </div>
  );
};

export default MessageBox;
