import { Divider, Space } from 'antd';
import { FC } from 'react';

import { OverviewMetricsParams } from '../../../api';
import { formatCurrency } from '../../../utils';

export const MetricsOverview: FC<{
  data?: OverviewMetricsParams;
}> = ({ data }) => {
  return (
    <Space direction="horizontal" wrap>
      <div>Gross Revenue: {formatCurrency(data?.grossRevenue)}</div>

      <Divider type="vertical" />

      <div>Net Revenue: {formatCurrency(data?.netRevenue)}</div>

      <Divider type="vertical" />

      <div>Computational Costs: {formatCurrency(data?.computationalCosts)}</div>

      <Divider type="vertical" />

      <div>Profits: {formatCurrency(data?.profits)}</div>

      <Divider type="vertical" />

      <div>Subscriptions Purchased: {data?.subscriptionsPurchased}</div>

      <Divider type="vertical" />

      <div>Credits Added: {data?.creditsAdded}</div>

      <Divider type="vertical" />

      <div>Credits Purchased: {data?.creditsPurchased}</div>

      <Divider type="vertical" />

      <div>Credits Spent: {data?.creditsSpent}</div>
    </Space>
  );
};
