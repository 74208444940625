import { AxiosRequestConfig } from 'axios';

export const ReadFineTuneRequest = (
  characterId: string,
): AxiosRequestConfig => {
  return {
    url: `v1/fine-tune`,
    method: 'get',
    params: { characterId },
  };
};
