import { Avatar, Space, Typography } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import {
  CharacterListRequest,
  CharacterParams,
  UserType,
  useAPIRequest,
} from '../../../../api';
import { CenteredContainer, If, Loader } from '../../../../common';
import { RouteData, limitTextLength } from '../../../../utils';
import CreateCharacter from './create-character.component';
import { useInitStore } from '../../../../stores';

const { Title } = Typography;

const Characters: React.FC<{
  rawRoutes?: { [key: string]: RouteData[] };
}> = ({ rawRoutes }) => {
  const user = useInitStore((state) => state.user);

  const { response, error, isLoading } = useAPIRequest<{
    data: CharacterParams[];
  }>(() => CharacterListRequest());

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Title
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          Characters
        </Title>

        <If condition={user?.type === UserType.Admin}>
          <CreateCharacter
            rawRoutes={rawRoutes}
            style={{
              marginBottom: '40px',
            }}
          />
        </If>

        <Loader error={error} isLoading={isLoading}>
          <Space size="large" style={{ width: '100%' }} wrap>
            {response?.data?.map((item) => (
              <Link
                to={`/character/${item.id}`}
                key={item.id}
                style={{ maxWidth: '100%' }}
              >
                <Space size="middle" direction="vertical" align="center">
                  <Avatar
                    size={160}
                    src={item?.avatarUrl}
                    icon={<QuestionOutlined />}
                  />

                  <div>{limitTextLength(item.name, 25)}</div>
                </Space>
              </Link>
            ))}
          </Space>
        </Loader>
      </Space>
    </CenteredContainer>
  );
};

export default Characters;
