import { FC, PropsWithChildren, HTMLAttributeAnchorTarget } from 'react';
import { Link, Path } from 'react-router-dom';

export const StatefulLink: FC<
  PropsWithChildren<{
    to: string | Partial<Path>;
    target?: HTMLAttributeAnchorTarget;
    style?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
  }>
> = ({ to, target, children, style, className, disabled = false }) => {
  return disabled ? (
    <span className={className} style={{ cursor: 'default', ...style }}>
      {children}
    </span>
  ) : (
    <Link to={to} target={target} className={className} style={style}>
      {children}
    </Link>
  );
};
