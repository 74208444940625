import { Divider, Space } from 'antd';
import { FC } from 'react';

import { OverviewCountsParams } from '../../../api';

export const CountsOverview: FC<{ data?: OverviewCountsParams }> = ({
  data,
}) => {
  return (
    <Space direction="horizontal" wrap>
      <div>Users: {data?.users}</div>

      <Divider type="vertical" />

      <div>Voice Generations: {data?.voiceGenerations}</div>

      <Divider type="vertical" />

      <div>
        Messages: {data?.messages?.free} free, {data?.messages?.paid} paid
      </div>

      <Divider type="vertical" />

      <div>
        Pictures: {data?.pictures?.free} free, {data?.pictures?.paid} paid
      </div>

      <Divider type="vertical" />

      <div>
        Videos: {data?.videos?.free} free, {data?.videos?.paid} paid
      </div>
    </Space>
  );
};
