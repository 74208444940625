import { Button, Form, Input, Select, Space } from 'antd';

import { KeyType, formRequestErrorHandler, useRequests } from '../../../api';
import { If } from '../../../common';
import { confirmDangerousAction } from '../../../utils';
import { KeyParams, getKeyRequests } from '../../../api';

const Key: React.FC<{
  data?: Partial<KeyParams>;
  onSuccess?: (data: KeyParams, key: string) => void;
}> = ({ data, onSuccess }) => {
  const [form] = Form.useForm();

  const { request, isLoading } = useRequests({
    requests: getKeyRequests(),
    callbacks: {
      onSuccess,
      onError: formRequestErrorHandler(form),
    },
  });

  const onRequest = (params: Omit<KeyParams, 'id'>) => {
    if (data?.id) request.update(data?.id, params);
    else request.create(params);
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        borderRadius: '8px',
        border: 'solid 1px #cccccc',
        padding: '20px',
      }}
    >
      <Form
        form={form}
        onFinish={onRequest}
        initialValues={{ type: KeyType.ElevenLabs, ...data }}
        requiredMark={false}
        style={{ width: '500px', maxWidth: '100%' }}
      >
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Type is required' }]}
        >
          <Select
            options={[{ label: 'ElevenLabs', value: KeyType.ElevenLabs }]}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="value"
          label="Value"
          rules={[{ required: true, message: 'Value is required' }]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <If condition={data?.id}>
          <Space direction="horizontal">
            <Button disabled={isLoading} onClick={form.submit} type="primary">
              Update
            </Button>

            <Button
              disabled={isLoading}
              onClickCapture={() =>
                confirmDangerousAction({
                  action: 'Delete',
                  name: 'this key',
                  onConfirm: () => request.delete(data?.id),
                })
              }
              type="primary"
              danger
            >
              Delete
            </Button>
          </Space>
        </If>

        <If condition={!data?.id}>
          <Button disabled={isLoading} onClick={form.submit} type="primary">
            Add
          </Button>
        </If>
      </Form>
    </Space>
  );
};

export default Key;
