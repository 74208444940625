import { datadogRum } from '@datadog/browser-rum';

import { UserParams } from '../../api';

export function initializeDatadogRum() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID as string,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN as string,
    site: process.env.REACT_APP_DATADOG_RUM_SITE,
    service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    env: process.env.NODE_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();
}

export function setDatadogRumUser({ id, ...user }: UserParams) {
  datadogRum.setUser({
    id: id.toString(),
    ...user,
  });
}
