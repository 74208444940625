import { RouteData } from '../../../utils';
import Keys from './keys.component';

export const KeyRoutes: RouteData[] = [
  {
    path: 'keys',
    component: Keys,
    authRequired: true,
  },
];
