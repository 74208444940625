import { Breadcrumb, Space, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { OverviewBreakdown, CenteredContainer } from '../../../../common';
import {
  useAPIRequest,
  ReadCharacterRequest,
  CharacterParams,
  CharacterBillingOverviewRequest,
  BillingPeriod,
} from '../../../../api';

const { Title } = Typography;

const CharacterOverview: React.FC = () => {
  const params = useParams();

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Breadcrumb style={{ marginTop: '40px' }}>
          <Breadcrumb.Item>
            <Link to={`/characters`}>Characters</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/character/${params.characterId}`}>
              {characterResponse?.name || '...'}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Overview</Breadcrumb.Item>
        </Breadcrumb>

        <Title
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          {characterResponse?.name} Overview
        </Title>

        <OverviewBreakdown
          configFactory={(period: BillingPeriod) =>
            CharacterBillingOverviewRequest(params.characterId!, period)
          }
        />
      </Space>
    </CenteredContainer>
  );
};

export default CharacterOverview;
