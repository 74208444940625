import { AxiosRequestConfig } from 'axios';

import { KeyParams } from './key-params.interface';

export const CreateKeyRequest = (
  data: Omit<KeyParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/key`,
    method: 'post',
    data,
  };
};
