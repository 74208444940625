export function formatDate(date?: Date) {
  return date?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export function getRemainingDays(date?: Date) {
  if (!date) return -1;

  const diffTime = date.valueOf() - new Date().valueOf();

  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
