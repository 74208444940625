import { gold, green } from '@ant-design/colors';
import { Button, Form, Input, Space, Typography } from 'antd';
import { useState } from 'react';

import {
  useAPIRequest,
  EmailParams,
  UpdateEmailRequest,
  formRequestErrorHandler,
} from '../../api';
import { useInitStore } from '../../stores';
import { createNotification, NotificationType } from '../../utils';
import { If } from './if.component';
import { Loader } from './loader';
import { SendConfirmationEmailButton } from './send-confirmation-email-button.component';

const { Text } = Typography;

export const EmailForm: React.FC = () => {
  const [form] = Form.useForm();
  const user = useInitStore((state) => state.user);
  const setEmail = useInitStore((state) => state.setEmail);

  // form.getFieldValue('email') needs a state refresh to work and takes time to become available on page load
  const [emailField, setEmailField] = useState<string>(user?.email as string);

  const { request, isLoading } = useAPIRequest<EmailParams>(
    UpdateEmailRequest,
    {
      immediate: false,
      onSuccess: (response) => {
        setEmail(response.email, response.emailConfirmed);

        createNotification({
          key: response?.email,
          message: `Confirmation email sent to ${response?.email}. Please check your inbox.`,
          type: NotificationType.Success,
          duration: 6,
        });
      },
      onError: formRequestErrorHandler(form),
    },
  );

  const EmailLabel = () => {
    return (
      <Space>
        Email
        <Text
          style={{
            color: user?.emailConfirmed ? green.primary : gold.primary,
          }}
        >
          ({user?.emailConfirmed ? 'Confirmed' : 'Not confirmed'})
        </Text>
      </Space>
    );
  };

  return (
    <Loader isLoading={isLoading}>
      <Form
        form={form}
        onFinish={request}
        initialValues={{ email: user?.email }}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          name="email"
          label={<EmailLabel />}
          rules={[
            {
              required: true,
              message: 'Email must not be empty',
            },
            {
              type: 'email',
              message: 'Email is invalid',
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input
            style={{ maxWidth: '350px' }}
            onChange={(e) => setEmailField(e.currentTarget.value)}
          />
        </Form.Item>

        <Space style={{ marginTop: '10px' }}>
          <Button
            disabled={isLoading || emailField === user?.email}
            onClick={form.submit}
            type="primary"
          >
            Change email
          </Button>

          <If condition={!user?.emailConfirmed}>
            <SendConfirmationEmailButton
              disabled={emailField !== user?.email}
            />
          </If>
        </Space>
      </Form>
    </Loader>
  );
};
