import { RouteData } from '../../../utils';
import { Payouts } from './payouts.component';

export const BillingRoutes: RouteData[] = [
  {
    path: 'payouts',
    component: Payouts,
    authRequired: true,
  },
];
