import { Space, Typography } from 'antd';

import { KeyListRequest, KeyParams, useAPIRequest } from '../../../api';
import { CRUDKey, CenteredContainer, Loader } from '../../../common';
import Key from './key.component';

const { Title } = Typography;

const Keys: React.FC = () => {
  const { response, setResponse, error, isLoading } = useAPIRequest<{
    data: KeyParams[];
  }>(() => KeyListRequest());

  const onInnerKeySuccess = (data: KeyParams, key: string) => {
    if (!response) return;

    if (key === CRUDKey.Create) {
      setResponse({ data: [...response.data, data] });
    } else if (key === CRUDKey.Update) {
      const newResponseData = response.data.concat();

      const index = newResponseData.findIndex((item) => item.id === data.id);

      if (index !== -1) {
        newResponseData[index] = data;

        setResponse({ data: newResponseData });
      }
    } else if (key === CRUDKey.Delete) {
      const newResponseData = response.data.concat();

      const index = newResponseData.findIndex((item) => item.id === data.id);

      if (index !== -1) {
        newResponseData.splice(index, 1);

        setResponse({ data: newResponseData });
      }
    }
  };

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Loader error={error} isLoading={isLoading}>
          <Title
            style={{
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            Keys
          </Title>

          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Space size="large" style={{ width: '100%' }} wrap>
              {response?.data?.map((item) => (
                <Key data={item} key={item.id} onSuccess={onInnerKeySuccess} />
              ))}

              <Key onSuccess={onInnerKeySuccess} />
            </Space>
          </Space>
        </Loader>
      </Space>
    </CenteredContainer>
  );
};

export default Keys;
