import { RouteData } from '../../utils';

import Invitation from './invitation/invitation.component';
import ChangePassword from './change-password/change-password.component';
import ForgotPassword from './forgot-password/forgot-password.component';
import SignIn from './sign-in/sign-in.component';
// import SignUp from './sign-up/sign-up.component';
import { AuthLayout } from '../../layouts/auth-layout/auth-layout.component';

export const AuthRoutes: RouteData[] = [
  { path: 'signin', component: SignIn, isAuthRoute: true, layout: AuthLayout },
  // { path: 'signup', component: SignUp, isAuthRoute: true, layout: AuthLayout },
  {
    path: 'forgot-password',
    component: ForgotPassword,
    isAuthRoute: true,
    layout: AuthLayout,
  },
  {
    path: 'change-password',
    component: ChangePassword,
    isAuthRoute: true,
    layout: AuthLayout,
  },
  {
    path: 'invitation',
    component: Invitation,
    isAuthRoute: true,
    layout: AuthLayout,
  },
];
