import { FC } from 'react';
import { Form, Input, Button, Typography, Space } from 'antd';
import Icon, { UserOutlined, LockOutlined } from '@ant-design/icons';

import { SignInRequest } from '../../../api';
import { useInitStore } from '../../../stores';
import { CenteredContainer, StatefulLink } from '../../../common';
import { useAuthRequest } from '../use-auth-request.hook';
import { AuthType } from '../auth-type.enum';
import { MailSvg } from '../auth-form-svg.component';
import GoogleAuth from '../google-auth.component';

import '../auth-form.component.css';

const { Title } = Typography;

const SignIn: FC = () => {
  const [form] = Form.useForm();

  const setAfterSignIn = useInitStore((state) => state.setAfterSignIn);

  const { requestForm, requestGoogle, isLoading } = useAuthRequest({
    form,
    formConfigFactory: SignInRequest,
    onSuccess: setAfterSignIn,
  });

  return (
    <CenteredContainer className="auth-form" vertical>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Title style={{ textAlign: 'center', fontSize: '28px' }}>Sign in</Title>

        {/*<StatefulLink
          to="/signup"
          disabled={isLoading}
          className="auth-form-link"
        >
          <span style={{ color: '#676767' }}>Don't have an account?</span> Sign
          up
  </StatefulLink>*/}

        <Form
          form={form}
          onFinish={requestForm}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          disabled={isLoading}
          className="auth-form-inner"
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Email must not be empty',
              },
              {
                type: 'email',
                message: 'Email is invalid',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              className="auth-form-input"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Password must not be empty' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              className="auth-form-input"
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: '0px' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="auth-form-button"
              loading={isLoading}
              icon={
                <Icon
                  component={MailSvg}
                  style={{ position: 'relative', top: '3px' }}
                />
              }
            >
              Sign in
            </Button>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StatefulLink
                to="/forgot-password"
                disabled={isLoading}
                className="auth-form-link"
              >
                Forgot password?
              </StatefulLink>
            </div>
          </Form.Item>
        </Form>

        <GoogleAuth
          authType={AuthType.SignIn}
          onSuccess={requestGoogle}
          loading={isLoading}
        />
      </Space>
    </CenteredContainer>
  );
};

export default SignIn;
