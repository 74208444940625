import { Layout } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { useScrollToTopOnRouteChange } from '../../common';
import { useAppQueryEffect } from '../../utils';
import AuthHeader from './auth-header.component';
import { useInitStore } from '../../stores';
import Init from '../../routes/app/init/init.component';

const { Content } = Layout;

export const AuthLayout: FC<PropsWithChildren<{ withInit?: boolean }>> = ({
  withInit,
  children,
}) => {
  useAppQueryEffect();
  useScrollToTopOnRouteChange();

  const initialized = useInitStore((state) => state.initialized);

  let layoutChildren = children;

  if (withInit && !initialized) layoutChildren = <Init />;

  return (
    <Layout>
      <Layout>
        <video className="background-video" autoPlay loop muted playsInline>
          <source src="/background.mp4" type="video/mp4" />
        </video>

        <AuthHeader className="transparent-background" />

        <Content className="layout-content-offset transparent-background">
          {layoutChildren}
        </Content>
      </Layout>
    </Layout>
  );
};
