import { CRUDKey } from '../../../common';
import { ImmediateRequest, Requests } from '../../hooks';
import { CreateFineTuneRequest } from './create-fine-tune.request';
import { FineTunedModelResponse } from './fine-tuned-model-response.interface';
import { ReadFineTuneRequest } from './read-fine-tune.request';

export function getFineTuneRequests(
  characterId: string,
): Requests<FineTunedModelResponse> {
  return {
    [CRUDKey.Create]: () => CreateFineTuneRequest(characterId),
    [CRUDKey.Read]: ImmediateRequest(() => ReadFineTuneRequest(characterId)),
  };
}
