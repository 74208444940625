import { Radio, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import {
  BillingPeriod,
  OverviewParams,
  UserType,
  useAPIRequest,
} from '../../../api';
import { MetricsOverview } from './metrics-overview.component';
import { CountsOverview } from './counts-overview.component';
import { MediaOverview } from './media-overview.component';
import { useInitStore } from '../../../stores';
import { Loader } from '../loader';

export const OverviewBreakdown: FC<{
  configFactory: (...rest: any[]) => AxiosRequestConfig<OverviewParams>;
  includeCharacter?: boolean;
}> = ({ configFactory, includeCharacter }) => {
  const user = useInitStore((state) => state.user);

  const [period, setPeriod] = useState<BillingPeriod>(BillingPeriod.Current);

  const { request, response, error, isLoading } = useAPIRequest<OverviewParams>(
    configFactory,
    { immediate: false },
  );

  useEffect(() => request(period), [period]);

  if (user && [UserType.Creator, UserType.Admin].includes(user.type)) {
    return (
      <Space direction="vertical">
        <Radio.Group value={period} onChange={(e) => setPeriod(e.target.value)}>
          <Radio.Button value={BillingPeriod.Current}>Current</Radio.Button>

          <Radio.Button value={BillingPeriod.AllTime}>All Time</Radio.Button>
        </Radio.Group>

        <Loader error={error} isLoading={isLoading}>
          <Space direction="vertical" style={{ marginTop: '20px' }}>
            <MetricsOverview data={response?.metrics} />

            <CountsOverview data={response?.counts} />

            <MediaOverview
              data={response?.media}
              includeCharacter={includeCharacter}
              style={{ marginTop: '20px' }}
            />
          </Space>
        </Loader>
      </Space>
    );
  }

  return <></>;
};
