import { Table, Typography } from 'antd';
import { FC } from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  PayoutListRequest,
  PayoutParams,
  UserType,
  useAPIRequest,
} from '../../../api';
import { Loader } from '../../../common';
import { useInitStore } from '../../../stores';
import { formatCurrency, formatDate } from '../../../utils';

const { Title } = Typography;

export const Payouts: FC = () => {
  const { response, error, isLoading } = useAPIRequest<{
    data: PayoutParams[];
  }>(PayoutListRequest);

  const user = useInitStore((state) => state.user);

  let columns: ColumnsType<PayoutParams> = [];

  if (user?.type === UserType.Admin) {
    columns.push({
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (_: any, item: PayoutParams) => <span>{item.user?.name}</span>,
    });
  }

  columns = columns.concat([
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => {
        return <span>{formatCurrency(amount)}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      render: (created: Date) => {
        return <span>{formatDate(created)}</span>;
      },
    },
  ]);

  return (
    <Loader error={error} isLoading={isLoading}>
      <Table
        title={() => <Title level={3}>Payouts</Title>}
        dataSource={response?.data}
        columns={columns}
        rowKey={(row) => row.id}
      />
    </Loader>
  );
};
