import { useEffect } from 'react';

import {
  useAPIRequest,
  ChatRequest,
  ChatInfo,
  MessageMedia,
} from '../../../../api';
import { CenteredContainer, Loader } from '../../../../common';
import MessageList from './message-list/message-list.component';

const Chat: React.FC<{
  id?: string;
  viewMedia?: (media: MessageMedia) => void;
}> = ({ id, viewMedia }) => {
  const { request, response, error, isLoading } = useAPIRequest<ChatInfo>(
    () => ChatRequest(id!),
    { immediate: false },
  );

  useEffect(() => {
    if (id) request();
  }, [id]);

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Loader error={error} isLoading={isLoading}>
        <MessageList
          messages={response?.messages}
          style={{ height: '500px' }}
          viewMedia={(item) => viewMedia?.(item.media!)}
          unlockMessageMedia={(item) => viewMedia?.(item.media!)}
        />
      </Loader>
    </CenteredContainer>
  );
};

export default Chat;
