import { Grid } from 'antd';

import { useAuthStore, useInitStore, useMiscStore } from '../../stores';

const { useBreakpoint } = Grid;

export function useLayoutDisplay() {
  const token = useAuthStore((state) => state.token);
  const user = useInitStore((state) => state.user);

  const siderCollapsed = useMiscStore((state) => state.siderCollapsed);
  const siderWidth = useMiscStore((state) => state.siderWidth);

  const breakpoint = useBreakpoint();

  /*
  has token:
    email confirmed:
      siderCollapsed true: drawer burger, logo, menu, header width - full
      siderCollapsed false: header width - partial
    email not confirmed:
      siderCollapsed true: logo, header width - full
      siderCollapsed false: logo, header width - full

  no token:
    siderCollapsed true: logo, auth, header width - full
    siderCollapsed false: logo, auth, header width - full
  */

  let displayLogo = false;
  let displayDrawer = false;
  let displayMenu = false;
  let displayAuth = false;
  let displaySider = false;
  let headerWidth = undefined;

  if (token) {
    if (user?.emailConfirmed) {
      if (user?.name) {
        displaySider = true;
        displayMenu = true;

        if (siderCollapsed) {
          displayDrawer = true;
          displayLogo = true;
        } else headerWidth = `calc(100% - ${siderWidth})`;
      } else {
        // during user onboarding

        displayLogo = true;
      }
    } else displayLogo = true;
  } else {
    displayLogo = true;
    displayAuth = true;
  }

  return {
    displayLogo,
    displayDrawer,
    displayMenu,
    displayAuth,
    displaySider,
    headerWidth,
    breakpoint,
  };
}
