import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { AxiosRequestConfig } from 'axios';
import { FC, PropsWithChildren, useEffect } from 'react';

import { RequestError, useAPIRequest } from '../../api';
import { confirmDangerousAction } from '../../utils';

export const RequestButton: FC<
  PropsWithChildren<{
    configFactory: (...rest: any[]) => AxiosRequestConfig<any>;
    onSuccess?: (response: any) => void;
    onError?: (error: RequestError) => void;
    onLoadingChanged?: (value: boolean) => void;
    confirmation?: {
      action: string;
      name: string;
    };
    disabled?: boolean;
    style?: React.CSSProperties;
    type?: ButtonType;
    size?: SizeType;
    icon?: React.ReactNode;
    danger?: boolean;
  }>
> = ({
  configFactory,
  onSuccess,
  onError,
  onLoadingChanged,
  disabled,
  confirmation,
  style,
  type,
  size,
  icon,
  danger,
  children,
}) => {
  const { request, isLoading } = useAPIRequest<{ url: string }>(configFactory, {
    immediate: false,
    onSuccess,
    onError,
  });

  useEffect(() => {
    onLoadingChanged?.(isLoading);
  }, [isLoading]);

  return (
    <Button
      type={type}
      size={size}
      danger={danger}
      loading={isLoading}
      icon={icon}
      onClick={
        confirmation
          ? () =>
              confirmDangerousAction({
                action: confirmation.action,
                actionText: 'Yes',
                cancelText: 'No',
                name: confirmation.name,
                dangerColor: false,
                onConfirm: request,
              })
          : request
      }
      disabled={disabled}
      style={style}
    >
      {children}
    </Button>
  );
};
