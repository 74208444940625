import { RouteData } from '../../../utils';
import ProfileEditor from './profile-editor/profile-editor.component';
import Account from './account/account.component';
import SettingsContainer from './settings-container.component';

export const SettingsRoutes: RouteData[] = [
  {
    path: 'settings',
    component: SettingsContainer,
    authRequired: true,
    routes: [
      {
        path: 'profile',
        component: ProfileEditor,
        index: true,
        authRequired: true,
      },
      {
        path: 'account',
        component: Account,
        authRequired: true,
      },
    ],
  },
];
