import create from 'zustand';

import { UserParams, ReadUserRequest, useAPIRequest } from '../api';
import { setDatadogRumUser } from '../third-party/libraries/datadog-rum';
import { isLocal } from '../common';
import { QueryValues } from '../utils';

interface InitStoreState {
  initialized: boolean;
  user?: UserParams;
  afterSignIn?: boolean; // signed-in in the current session
  afterSignUp?: boolean; // signed-up in the current session
  setUser: (user: UserParams) => void;
  setEmail: (email: string, emailConfirmed?: boolean) => void;
  setAfterSignIn: () => void;
  setAfterSignUp: () => void;
  resetInitData: () => void;
  setInitialized: () => void;
  forwardedToken?: string; // invitation or forgot password
  setForwardedToken: (token: string) => void;
  queryValues?: QueryValues;
  setQueryValues: (queryValues: QueryValues) => void;
}

export const useInitStore = create<InitStoreState>((set) => ({
  initialized: false,
  afterSignIn: false,
  afterSignUp: false,
  projects: [],
  setUser: (user: UserParams) => {
    set({ user });

    if (!isLocal()) {
      setDatadogRumUser(user);
    }
  },
  setEmail: (email: string, emailConfirmed?: boolean) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), email, emailConfirmed },
    }));
  },
  setAfterSignIn: () => set({ afterSignIn: true }),
  setAfterSignUp: () => set({ afterSignUp: true }),
  resetInitData: () => {
    set({
      initialized: false,
      afterSignIn: false,
      afterSignUp: false,
      user: undefined,
    });
  },
  setInitialized: () => set({ initialized: true }),
  setForwardedToken: (token: string) => {
    set({ forwardedToken: token });
  },
  setQueryValues: (queryValues: QueryValues) => set({ queryValues }),
}));

export const useRefreshUser = () => {
  const setUser = useInitStore((state) => state.setUser);

  const { request, response } = useAPIRequest<UserParams>(ReadUserRequest, {
    immediate: false,
    onSuccess: setUser,
  });

  return { refreshUser: request, user: response };
};
