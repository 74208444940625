import { FC } from 'react';

import { AppLayout } from '../layouts/app/app-layout.component';
import { useAuthStore, useInitStore } from '../stores';
import { navigateToDefaultPath, RoutesRenderer } from '../utils';

import { AdminRoutes } from './admin/admin.routes';
import { AuthRoutes } from './auth/auth.routes';
import { AppRoutes } from './app/app.routes';
import PrivateIndex from './app/private-index/private-index.component';
import { UserType } from '../api';
import { RequiredRoutes } from './required/required.routes';
import SignIn from './auth/sign-in/sign-in.component';
import { AuthLayout } from '../layouts/auth-layout/auth-layout.component';

import './router.component.css';

const rawRoutes = [...AppRoutes, ...RequiredRoutes];

const PublicRoutes = rawRoutes.filter((route) => !route.authRequired);
const PrivateRoutes = rawRoutes.filter((route) => route.authRequired);

const Router: FC = () => {
  const token = useAuthStore((state) => state.token);
  const user = useInitStore((state) => state.user);

  let routes = [...PublicRoutes, ...PrivateRoutes];

  if (token) {
    // waiting for user to load in case it's not set
    if (
      !user ||
      [UserType.Admin, UserType.Creator, UserType.ContentManager].includes(
        user.type,
      )
    ) {
      routes = routes.concat(AdminRoutes);
    }

    return (
      <RoutesRenderer
        layout={AppLayout}
        layoutProps={{ withInit: true }}
        routes={[
          {
            path: '/',
            component: PrivateIndex,
          },
          ...routes,
          ...navigateToDefaultPath(AuthRoutes),
        ]}
        isAuth
      />
    );
  } else {
    routes = routes.concat(AuthRoutes);

    return (
      <RoutesRenderer
        layout={AppLayout}
        layoutProps={{ withInit: false }}
        routes={[
          {
            path: '/',
            component: SignIn,
            isAuthRoute: true,
            layout: AuthLayout,
          },
          ...routes,
        ]}
      />
    );
  }
};

export default Router;
