import { CSSProperties, FC } from 'react';
import { Select } from 'antd';

import {
  KeyListRequest,
  KeyParams,
  SelectItem,
  useAPIRequest,
} from '../../api';

export const KeySelect: FC<{
  value?: string;
  onChange?: (
    value: string,
    option: SelectItem<string> | SelectItem<string>[],
  ) => void;
  style?: CSSProperties;
}> = ({ style, value, onChange, ...rest }) => {
  const { response, isLoading } = useAPIRequest<{
    data: KeyParams[];
  }>(KeyListRequest);

  const options = response?.data?.map((key) => ({
    label: key.name,
    value: key.id,
  }));

  return (
    <Select
      placeholder="Select a key..."
      loading={isLoading}
      options={options}
      defaultValue={options?.[0]?.value}
      value={value}
      onChange={onChange}
      style={style}
      {...rest}
    />
  );
};
