export enum UserType {
  Admin = 'admin',
  Creator = 'creator',
  Normal = 'normal',
  ContentManager = 'contentManager',
}

export function getUserTypeLabel(type: UserType): string {
  switch (type) {
    case UserType.Admin:
      return 'Admin';
    case UserType.Creator:
      return 'Creator';
    case UserType.Normal:
      return 'Normal';
    case UserType.ContentManager:
      return 'Content Manager';
  }
}
