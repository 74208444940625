import { useCallback, useMemo, useRef } from 'react';

import MessageBox from './message-box.component';
import { MediaType, Message } from '../../../../../api';
import { ParsedMessagePosition } from './parsed-message-position.enum';
import { ParsedMessageType } from './parsed-message-type.enum';
import { MessageListItem } from './message-list-item.interface';
import { ParsedMessage } from './parsed-message.interface';

import './message-list.component.css';

const MessageList: React.FC<{
  messages?: Message[];
  unlockMessageMedia?: (data: ParsedMessage) => any;
  viewMedia?: (data: ParsedMessage) => any;
  style?: React.CSSProperties;
}> = ({ messages, unlockMessageMedia, viewMedia, style }) => {
  const previousParsedMessagesLength = useRef<number>(0);
  const messageListContainerRef = useRef<any>();

  const scrollToBottom = useCallback(() => {
    messageListContainerRef.current?.scroll({
      top: messageListContainerRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  }, [messageListContainerRef]);

  const parsedMessages = useMemo(() => {
    if (!messages) return [];

    const newParsedMessages = messages.flatMap((message) => {
      const result: MessageListItem[] = [];

      if (message.messageVoiceUrl) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-voice',
            position: ParsedMessagePosition.Right,
            type: ParsedMessageType.Voice,
            value: message.messageVoiceUrl,
            sent: message.sent,
          },
        });
      } else if (message.message) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-message',
            position: ParsedMessagePosition.Right,
            type: ParsedMessageType.Text,
            value: message.message,
            sent: message.sent,
          },
        });
      }

      if (message.media) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-' + message.media.type,
            position: ParsedMessagePosition.Left,
            type:
              message.media.type === MediaType.Picture
                ? ParsedMessageType.Picture
                : ParsedMessageType.Video,
            sent: message.sent,
            media: message.media,
          },
        });
      }

      if (message.response) {
        if (message.responseVoiceUrl) {
          result.push({
            message: {
              id: message.id!,
              key: message.id + '-response-voice',
              position: ParsedMessagePosition.Left,
              type: ParsedMessageType.Voice,
              value: message.responseVoiceUrl,
              sent: message.sent,
            },
          });
        } else {
          result.push({
            message: {
              id: message.id!,
              key: message.id + '-response-text',
              position: ParsedMessagePosition.Left,
              type: ParsedMessageType.Text,
              value: message.response,
              sent: message.sent,
            },
          });
        }
      } else if (!message.media) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-response-loader',
            position: ParsedMessagePosition.Left,
            type: ParsedMessageType.Text,
            value: message.response,
            sent: message.sent,
            loading: true,
          },
        });
      }

      return result;
    });

    if (previousParsedMessagesLength.current !== newParsedMessages.length) {
      scrollToBottom();

      setTimeout(() => scrollToBottom(), 10);
    }

    previousParsedMessagesLength.current = newParsedMessages.length;

    return newParsedMessages;
  }, [messages]);

  return (
    <div className="message-list" style={style} ref={messageListContainerRef}>
      {parsedMessages?.map((item) => {
        if (item.message) {
          return (
            <MessageBox
              data={item.message}
              unlockMessageMedia={unlockMessageMedia}
              viewMedia={viewMedia}
              key={item.message.key}
            />
          );
        } else {
          return (
            <div key={item.date} className="date">
              {item.date}
            </div>
          );
        }
      })}
    </div>
  );
};

export default MessageList;
