import { AxiosRequestConfig } from 'axios';

export const VoiceListRequest = (
  elevenLabsKey?: string,
): AxiosRequestConfig => {
  return {
    url: `v1/voice/list`,
    method: 'get',
    params: { elevenLabsKey },
  };
};
