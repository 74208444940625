import { useRef } from 'react';
import { Breadcrumb, Space, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import {
  CharacterParams,
  CreateTransloaditAssembliesRequest,
  TransloaditAssemblyListRequest,
  MediaListRequest,
  MediaParams,
  ReadCharacterRequest,
  useAPIRequest,
  TransloaditAssemblyParams,
  TransloaditAssemblyStatus,
  UserType,
} from '../../../../api';
import { CenteredContainer, If, Loader } from '../../../../common';
import AddMedia from './add-media.component';
import { NotificationType, createNotification } from '../../../../utils';
import MediaItem from './media-item.component';
import { useInitStore } from '../../../../stores';

const { Title } = Typography;

const Medias: React.FC = () => {
  const params = useParams();
  const user = useInitStore((state) => state.user);

  const list = useRef<MediaParams[]>([]);

  const { response, setResponse, error, isLoading } = useAPIRequest<{
    data: MediaParams[];
  }>(() => MediaListRequest(params.characterId!), {
    onSuccess: (value) => {
      list.current = value.data;
    },
  });

  const {
    request: getTransloaditAssemblyList,
    isLoading: isLoadingTransloaditAssemblyList,
    response: transloaditAssemblyListResponse,
  } = useAPIRequest<{
    totalFilesProcessing: number;
    data: TransloaditAssemblyParams[];
  }>(() => TransloaditAssemblyListRequest(params.characterId!), {
    shouldRetry: (value) => value.data.length > 0,
    retryInterval: 3000,
    onSuccess: (value) => {
      let newMedias: MediaParams[] = [];

      value.data.map((assembly) => {
        if (assembly.status === TransloaditAssemblyStatus.Completed) {
          newMedias = newMedias.concat(assembly.medias);
        } else if (assembly.status === TransloaditAssemblyStatus.Error) {
          createNotification({
            key: 'serverError',
            message: assembly.error || 'Upload error',
            type: NotificationType.Error,
          });
        }
      });

      if (newMedias.length > 0) {
        // setResponse is problematic, different TransloaditAssemblyListRequest responses can override each other
        // as the request keeps retrying with the same js context until all the assemblies are completed
        // using a ref as a helper workaround

        list.current = list.current.concat(newMedias);

        setResponse({ data: list.current });
      }
    },
  });

  const { request: createAssemblies } =
    useAPIRequest<TransloaditAssemblyParams>(
      (assemblyIds: string[], price: number) =>
        CreateTransloaditAssembliesRequest(
          params.characterId!,
          assemblyIds,
          price,
        ),
      {
        immediate: false,
        onSuccess: () => {
          if (!isLoadingTransloaditAssemblyList) getTransloaditAssemblyList();
        },
      },
    );

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  const afterUploadRef = useRef(createAssemblies);

  afterUploadRef.current = createAssemblies;

  const totalFilesProcessing =
    transloaditAssemblyListResponse?.totalFilesProcessing || 0;

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Loader error={error} isLoading={isLoading}>
          <Breadcrumb style={{ marginTop: '40px' }}>
            <Breadcrumb.Item>
              <Link to={`/characters`}>Characters</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/character/${params.characterId}`}>
                {characterResponse?.name || '...'}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>Media</Breadcrumb.Item>
          </Breadcrumb>

          <Title
            style={{
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            Media
          </Title>

          <If
            condition={
              user?.type === UserType.Admin || user?.type === UserType.Creator
            }
          >
            <AddMedia
              characterId={params.characterId!}
              afterUploadRef={afterUploadRef}
              style={{ marginBottom: '20px' }}
            />
          </If>

          <Space size="large" style={{ width: '100%' }} wrap>
            {response?.data?.map((item) => (
              <MediaItem
                characterId={params.characterId!}
                data={item}
                key={item.id}
              />
            ))}
          </Space>

          <If condition={totalFilesProcessing > 0}>
            <Space
              direction="horizontal"
              size="middle"
              style={{ marginTop: '40px' }}
            >
              <div>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </div>
              {`Processing ${totalFilesProcessing} file${
                totalFilesProcessing !== 1 ? 's' : ''
              }...`}
            </Space>
          </If>
        </Loader>
      </Space>
    </CenteredContainer>
  );
};

export default Medias;
