import { Avatar, Breadcrumb, List, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  ReadCharacterRequest,
  useAPIRequest,
  ChatParams,
  CharacterParams,
  ChatListRequest,
} from '../../../../api';
import { CenteredContainer, Loader } from '../../../../common';
import Chat from './chat.component';

const { Title } = Typography;

const Chats: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [selectedChatId, setSelectedChatId] = useState<string>();

  const { request, response, error, isLoading } = useAPIRequest<{
    data: ChatParams[];
  }>(() => ChatListRequest(params.characterId!), { immediate: false });

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  useEffect(() => {
    if (params.characterId) request();
  }, [params.characterId]);

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Loader error={error} isLoading={isLoading}>
        <Breadcrumb style={{ marginTop: '40px' }}>
          <Breadcrumb.Item>
            <Link to={`/characters`}>Characters</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/character/${params.characterId}`}>
              {characterResponse?.name || '...'}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Chat</Breadcrumb.Item>
        </Breadcrumb>

        <Title
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          Chat
        </Title>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <List
            dataSource={response?.data}
            renderItem={(item) => (
              <List.Item
                id={item.id}
                key={item.id}
                onClick={() => setSelectedChatId(item.id)}
                style={{
                  padding: '10px',
                  backgroundColor:
                    selectedChatId === item.id ? '#cccccc' : undefined,
                  userSelect: 'none',
                  cursor: 'pointer',
                }}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.user.avatarUrl} />}
                  title={item.user.name}
                  description={item.user.email}
                />
                <div>{item.totalMessages}</div>
              </List.Item>
            )}
            style={{
              width: '100%',
              maxWidth: '400px',
              marginRight: '20px',
              height: '500px',
              overflowY: 'scroll',
            }}
          />

          <Chat
            id={selectedChatId}
            viewMedia={(media) =>
              navigate(`/character/${params.characterId}/media/${media.id}`)
            }
          />
        </div>
      </Loader>
    </CenteredContainer>
  );
};

export default Chats;
