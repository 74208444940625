import { CSSProperties, FC, useEffect, useMemo } from 'react';
import { Select } from 'antd';

import {
  Gender,
  PlainLanguageCode,
  useAPIRequest,
  VoiceListRequest,
  VoiceParams,
  VoiceProvider,
} from '../../../../api';

export const VoiceSelect: FC<{
  voiceProvider?: VoiceProvider;
  elevenLabsKey?: string;
  gender?: Gender;
  language?: PlainLanguageCode;
  value?: string;
  onChange?: (value: string, option: VoiceParams | VoiceParams[]) => void;
  style?: CSSProperties;
}> = ({
  voiceProvider,
  elevenLabsKey,
  gender,
  language,
  style,
  value,
  onChange,
  ...rest
}) => {
  const { request, response, isLoading } = useAPIRequest<{
    [key: string]: VoiceParams[];
  }>(VoiceListRequest, { immediate: false });

  const options = useMemo(() => {
    if (!response) return [];

    const list = response[voiceProvider as string];

    if (!list) return [];

    return list.filter(
      (value) =>
        value.gender === gender &&
        (voiceProvider === VoiceProvider.ElevenLabs ||
          value.language === language),
    );
  }, [response, voiceProvider, gender, language]);

  const currentValue = options?.find((option) => option.value === value)
    ? value
    : undefined;

  useEffect(() => {
    request(elevenLabsKey);
  }, [elevenLabsKey]);

  return (
    <Select
      placeholder="Select a voice..."
      loading={isLoading}
      options={options}
      defaultValue={options?.[0]?.value}
      value={currentValue}
      onChange={onChange}
      style={style}
      {...rest}
    />
  );
};
