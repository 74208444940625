import { Typography } from 'antd';

import { CenteredContainer, EmailForm } from '../../../../common';

const { Title } = Typography;

const Account: React.FC = () => {
  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Title style={{ marginTop: '40px', marginBottom: '40px' }}>Account</Title>

      <EmailForm />
    </CenteredContainer>
  );
};

export default Account;
