import { FC, PropsWithChildren, useMemo } from 'react';
import {
  Route,
  Navigate,
  BrowserRouter,
  Routes,
  matchPath,
} from 'react-router-dom';

import { AppRoutes } from '../../../routes/app/app.routes';
import { AuthRoutes } from '../../../routes/auth/auth.routes';
import { usePersistedStore } from '../../../stores';
import { RouteData } from '../interfaces';
import { PersistDefaultPath } from './persist-default-path.component';
import { RedirectAndPersistPreviousPath } from './redirect-and-persist-previous-path.component';
import { AdminRoutes } from '../../../routes/admin/admin.routes';
import { RequiredRoutes } from '../../../routes/required/required.routes';

const rawRoutes: { [key: string]: RouteData[] } = {
  auth: AuthRoutes,
  app: AppRoutes,
  required: RequiredRoutes,
  admin: AdminRoutes,
};

rawRoutes.all = Object.values(rawRoutes).reduce(
  (previousValue, currentValue) => [...previousValue, ...currentValue],
  [],
);

function createRoutes({
  data,
  layout: outerLayout,
  layoutProps,
  parentPath = [],
  isAuth = false,
}: {
  data: RouteData[];
  layout?: FC<PropsWithChildren>;
  layoutProps?: any;
  parentPath?: string[];
  isAuth?: boolean;
}) {
  return data.flatMap(
    ({
      path,
      component: Component,
      index,
      routes,
      authRequired,
      isAuthRoute,
      props,
      layout: innerLayout,
    }) => {
      // injecting rawRoutes to avoid a circular dependency in case one of the routes or layouts wants to access the routes list
      let element = <Component rawRoutes={rawRoutes} {...props} />;

      const LayoutComponent = innerLayout || outerLayout;

      if (LayoutComponent) {
        element = (
          <LayoutComponent rawRoutes={rawRoutes} {...layoutProps}>
            {element}
          </LayoutComponent>
        );
      }

      const children =
        routes &&
        createRoutes({
          data: routes,
          parentPath: [...parentPath, path],
          isAuth,
        }); // not passing LayoutComponent, it's not relevant to nested routes

      const result = [];

      if (index) {
        result.push(
          <Route index element={element} key={`route-${path}-index`} />,
        ); // index routes can't have children
      }

      // if route is protected by auth and user isn't authorized, navigate to signin
      if (!isAuth && authRequired) {
        result.push(
          <Route
            path={path}
            element={<RedirectAndPersistPreviousPath to="/signin" />}
            key={`route-${path}`}
          >
            {children}
          </Route>,
        );
      } else {
        result.push(
          <Route
            path={path}
            element={
              isAuthRoute ? element : <PersistDefaultPath element={element} />
            }
            key={`route-${path}`}
          >
            {children}
          </Route>,
        );
      }

      return result;
    },
  );
}

function pathExists(
  routes: RouteData[],
  path: string,
  parentRoutes: string[] = [],
): boolean {
  const basePath = path.split('?')[0];

  return routes.some((route) => {
    const matchRoute = matchPath(
      [...parentRoutes, route.path].join('/'),
      basePath,
    );

    const matchChildRoutes =
      route.routes &&
      pathExists(route.routes, path, [...parentRoutes, route.path]);

    return matchRoute || matchChildRoutes;
  });
}

export const RoutesRenderer = ({
  routes,
  layout,
  layoutProps,
  isAuth,
}: {
  layout: FC<PropsWithChildren<any>>;
  layoutProps?: any;
  routes: RouteData[];
  isAuth?: boolean;
}) => {
  const defaultPath = usePersistedStore((state) => state.defaultPath);

  const children = useMemo(() => {
    const result = createRoutes({
      data: routes,
      layout,
      layoutProps,
      isAuth,
    });

    // makes sure the defaultPath exists in data in order to not set a non existing route as the default
    // if current route isn't found, it's most likely an auth route (signin/signup) that was removed because the user just authenticated
    // in this case, navigating the user to the protected route he initially tried to access before being redirected to auth
    if (defaultPath && pathExists(routes, defaultPath)) {
      result.push(
        <Route
          path="*"
          element={<Navigate to={defaultPath} replace />}
          key="default-route"
        />,
      );
    }

    return result;
  }, [routes, layout, layoutProps, isAuth, defaultPath]);

  return <BrowserRouter>{<Routes>{children}</Routes>}</BrowserRouter>;
};
