import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { persist } from 'zustand/middleware';

import { AuthResponse } from '../api';

interface AuthStoreState extends Partial<AuthResponse> {
  setCredentials: (credentials: AuthResponse) => void;
  clearCredentials: () => void;
}

export const authStore = createVanilla<AuthStoreState>(
  persist<AuthStoreState>(
    (set) => ({
      token: undefined,
      user: undefined,
      setCredentials: (credentials: AuthResponse) => set(credentials),
      clearCredentials: () => set({ token: undefined, user: undefined }),
    }),
    {
      name: 'auth',
    },
  ),
);

export const useAuthStore = create<AuthStoreState>(authStore);
