import { FC } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import { NotificationType, createNotification } from '../../utils';
import { AuthType } from './auth-type.enum';

const GoogleAuth: FC<{
  authType: AuthType;
  onSuccess?: (token: string) => void;
  loading?: boolean;
  style?: React.CSSProperties;
}> = ({ authType, onSuccess, style, loading = false }) => {
  const innerStyle: React.CSSProperties = { width: '100%' };

  if (loading) {
    innerStyle.pointerEvents = 'none';
    innerStyle.opacity = 0.5;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <div style={{ ...innerStyle, ...style }}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            if (credentialResponse.credential) {
              onSuccess?.(credentialResponse.credential);
            }
          }}
          onError={() => {
            const messageType =
              authType === AuthType.SignIn ? 'Sign In' : 'Sign Up';

            createNotification({
              key: 'googleAuth',
              message: `${messageType} with google failed`,
              type: NotificationType.Error,
            });
          }}
          type="standard"
          shape="rectangular"
          theme="filled_blue"
          text={`${authType}_with`}
          logo_alignment="left"
          width="333px"
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
