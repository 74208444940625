import { Layout } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { useLayoutDisplay, useScrollToTopOnRouteChange } from '../../common';
import { useAppQueryEffect } from '../../utils';
import AppHeader from './app-header/app-header.component';
import { AppSider } from './app-sider.component';
import { useLayoutChildren } from '../use-layout-children.hook';

const { Content } = Layout;

export const AppLayout: FC<PropsWithChildren<{ withInit?: boolean }>> = ({
  children,
  withInit = false,
}) => {
  const layoutChildren = useLayoutChildren(children);

  const { displaySider } = useLayoutDisplay();

  useAppQueryEffect();
  useScrollToTopOnRouteChange();

  return (
    <Layout>
      {displaySider && <AppSider />}

      <Layout>
        <AppHeader />

        <Content className="layout-content-offset">
          {withInit ? layoutChildren : children}
        </Content>
      </Layout>
    </Layout>
  );
};
