import { AxiosRequestConfig } from 'axios';

export const DeleteFineTuneExampleRequest = (
  id: string,
): AxiosRequestConfig => {
  return {
    url: `v1/fine-tune/example/${id}`,
    method: 'delete',
  };
};
