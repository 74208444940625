import { AxiosRequestConfig } from 'axios';

import { VoiceOptions } from './voice-options.interface';
import { PlainLanguageCode } from './plain-language-code.enum';

export const TestVoiceRequest = (
  name: string,
  language: PlainLanguageCode,
  options: VoiceOptions,
): AxiosRequestConfig => {
  return {
    url: `v1/voice/test`,
    method: 'post',
    data: { name, language, ...options },
  };
};
