import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import {
  SaveOutlined,
  DeleteOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  useRequests,
  formRequestErrorHandler,
  getMediaRequests,
  ReadCharacterRequest,
  useAPIRequest,
  CharacterParams,
  UserType,
  MediaType,
} from '../../../../api';
import { CenteredContainer, Loader, CRUDKey, If } from '../../../../common';
import {
  capitalizeFirstLetter,
  confirmDangerousAction,
  createNotification,
  NotificationType,
  valuesToOptions,
} from '../../../../utils';
import ImagePicker from './image-picker.component';
import { useInitStore } from '../../../../stores';

const { TextArea } = Input;

const DEFAULT_TAGS = [
  'naked',
  'clothed',
  'toy',
  'rubber',
  'dildo',
  'condom',
  'bareback',
  'lingerie',
  'bikini',
  'stockings',
  'fishnets',
  'feet',
  'anal',
  'vaginal',
  'oral',
  'ass',
  'fuck',
  'boobs',
  'doggy',
  'riding',
  'blowjob',
  'cum',
  'orgasm',
  'swallow',
  'cowgirl',
  'masturbation',
  'doggystyle',
  'dick',
  'white',
  'black',
  'rimming',
  'massage',
  'fingering',
  'teasing',
  'pov',
  'creampie',
  'shower',
  'bathtub',
  'moaning',
  'femdom',
  'domination',
  'submission',
  'posing',
];

const rotations = [0, 90, 180, 270];

const Media: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const user = useInitStore((state) => state.user);

  const back = () => navigate(`/character/${params.characterId}/media`);

  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [rotationIndex, setRotationIndex] = useState<number>(0);

  const { request, requestKey, response, isLoading, error } = useRequests({
    requests: getMediaRequests(params.mediaId!),
    callbacks: {
      onSuccess: (data, key) => {
        form.setFieldsValue(data);

        setPreviewIndex(data.previewIndex);
        setRotationIndex(0);

        if (key === CRUDKey.Update) {
          createNotification({
            key: 'mediaUpdated',
            message: 'Media saved successfully.',
            type: NotificationType.Success,
            duration: 6,
          });
        } else if (key === CRUDKey.Delete) {
          createNotification({
            key: 'mediaDeleted',
            message: 'Media deleted successfully.',
            type: NotificationType.Success,
            duration: 6,
          });

          back();
        }
      },
      onError: formRequestErrorHandler(form),
    },
  });

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  useEffect(() => {
    if (params.mediaId) request.read();
  }, [params.mediaId]);

  const rotateLeft = () => {
    if (rotationIndex > 0) {
      setRotationIndex(rotationIndex - 1);
    } else {
      setRotationIndex(rotations.length - 1);
    }
  };

  const rotateRight = () => {
    if (rotationIndex < rotations.length - 1) {
      setRotationIndex(rotationIndex + 1);
    } else {
      setRotationIndex(0);
    }
  };

  const limitedMode =
    user?.type !== UserType.Admin && user?.type !== UserType.ContentManager;

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Loader
        error={requestKey === CRUDKey.Read ? error : undefined}
        isLoading={isLoading}
      >
        <Breadcrumb style={{ marginTop: '40px' }}>
          <Breadcrumb.Item>
            <Link to={`/characters`}>Characters</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/character/${params.characterId}`}>
              {characterResponse?.name || '...'}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/character/${params.characterId}/media`}>Media</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Form
          form={form}
          onFinish={(params) =>
            request.update(params, rotations[rotationIndex])
          }
          initialValues={response}
          layout="vertical"
          requiredMark={false}
        >
          <Row align="top" style={{ marginTop: '40px' }}>
            <Col flex="200px" style={{ marginRight: '40px' }}>
              <Form.Item label={capitalizeFirstLetter(response?.type || '')}>
                <a href={response?.url} target="_blank">
                  <img
                    src={
                      response?.thumbnailUrls?.length || 0 > 0
                        ? response?.thumbnailUrls?.[previewIndex]
                        : response?.thumbnailUrl
                    }
                    style={{
                      width: '260px',
                      height: '260px',
                      objectFit: 'cover',
                      cursor: 'pointer',
                      transform: `rotate(${rotations[rotationIndex]}deg)`,
                    }}
                  />
                </a>

                <If condition={response?.type === MediaType.Picture}>
                  <Space direction="horizontal" size={0}>
                    <Button
                      icon={<RotateRightOutlined />}
                      style={{ border: 0 }}
                      onClick={rotateRight}
                    />

                    <Button
                      icon={<RotateLeftOutlined />}
                      style={{ border: 0 }}
                      onClick={rotateLeft}
                    />
                  </Space>
                </If>
              </Form.Item>

              <If condition={response?.thumbnailUrls?.length || 0 > 0}>
                <Form.Item name="previewIndex">
                  <ImagePicker
                    data={response?.thumbnailUrls}
                    onChange={(index) => setPreviewIndex(index)}
                  />
                </Form.Item>
              </If>
            </Col>

            <Col flex="1" style={{ minWidth: 'min(450px, 100%)' }}>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <If condition={response?.name}>
                  <Form.Item label="Name">
                    <b>{response?.name}</b>
                  </Form.Item>
                </If>

                <If condition={response?.width || 0 > 0}>
                  <Form.Item label="Dimensions">
                    <b>
                      {response?.width} x {response?.height}
                    </b>
                  </Form.Item>
                </If>

                <If condition={response?.missingIndex}>
                  <Form.Item>
                    <b style={{ color: 'red' }}>Missing Index</b>
                  </Form.Item>
                </If>

                <If condition={response?.duration}>
                  <Form.Item label="Duration">
                    <b>{response?.duration}</b>
                  </Form.Item>
                </If>

                <If condition={!limitedMode}>
                  <Form.Item
                    name="description"
                    label="Description"
                    tooltip="A description of how you look like and what you do in this media. This helps our AI respond to user requests with the most relevant media."
                    rules={[
                      {
                        max: 2000,
                        message: 'Description must be maximum 2000 characters',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 6, maxRows: 10 }}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>

                  <Form.Item name="tags" label="Tags">
                    <Select
                      mode="tags"
                      allowClear
                      placeholder="Enter or select a tag"
                      options={valuesToOptions(DEFAULT_TAGS)}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>
                </If>

                <Form.Item
                  name="price"
                  label="Price"
                  tooltip="The price in US dollars that the user will be asked to pay to unlock this media."
                  rules={[
                    {
                      required: true,
                      message: 'Price is required',
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ maxWidth: '500px' }} />
                </Form.Item>

                <Form.Item
                  name="welcome"
                  label="Welcome"
                  valuePropName="checked"
                  tooltip="This will be attached to the welcome message in new chats."
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  name="reengagement"
                  label="Reengagement"
                  valuePropName="checked"
                  tooltip="Whether or not this media can be used in reengagement messages. Users will still have to pay to unlock it if it's not free."
                >
                  <Switch />
                </Form.Item>

                <If condition={!limitedMode}>
                  <Form.Item
                    name="published"
                    label="Published"
                    valuePropName="checked"
                    tooltip="Whether or not this media is publicly available to users."
                  >
                    <Switch />
                  </Form.Item>
                </If>

                <Space>
                  <Button
                    disabled={isLoading}
                    onClick={form.submit}
                    size="large"
                    type="primary"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>

                  <If
                    condition={
                      user?.type === UserType.Admin ||
                      user?.type === UserType.Creator
                    }
                  >
                    <Button
                      disabled={isLoading}
                      onClick={() =>
                        confirmDangerousAction({
                          action: 'Delete',
                          name: 'this media',
                          onConfirm: request.delete,
                        })
                      }
                      size="large"
                      type="primary"
                      danger
                      icon={<DeleteOutlined />}
                    >
                      Delete
                    </Button>
                  </If>
                </Space>
              </Space>
            </Col>
          </Row>
        </Form>
      </Loader>
    </CenteredContainer>
  );
};

export default Media;
