import { AxiosRequestConfig } from 'axios';

import { CharacterParams } from './character-params.interface';

export const UpdateCharacterRequest = (
  id: string,
  params: Omit<CharacterParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/character/${id}`,
    method: 'put',
    data: params,
  };
};
