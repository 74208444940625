import { Col, Layout, theme } from 'antd';
import { FC } from 'react';

import AccountMenu from './account-menu/account-menu.component';
// import AuthButtons from './auth-buttons.component';
import { AppDrawer } from './app-drawer/app-drawer.component';
import { Logo } from '../logo.component';
import { useLayoutDisplay } from '../../../common';

import './app-header.component.css';

const { Header } = Layout;

const AppHeader: FC<{
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({ style, ...props }) => {
  const {
    displayLogo,
    displayDrawer,
    displayMenu,
    //displayAuth,
    headerWidth,
    breakpoint,
  } = useLayoutDisplay();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Header
      style={{
        background: colorBgContainer,
        width: headerWidth,
        paddingInline: breakpoint.xs ? '20px' : '50px',
        ...style,
      }}
      {...props}
    >
      {displayDrawer && <AppDrawer style={{ marginRight: '20px' }} />}
      {displayLogo && <Logo />}

      <Col flex={1} />

      {displayMenu && <AccountMenu />}
      {/*displayAuth && <AuthButtons />*/}
    </Header>
  );
};

export default AppHeader;
