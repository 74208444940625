import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Typography,
} from 'antd';
import { RuleObject } from 'antd/es/form';
import {
  SaveOutlined,
  DeleteOutlined,
  PictureOutlined,
  BranchesOutlined,
  MessageOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  useRequests,
  formRequestErrorHandler,
  getCharacterRequests,
  VoiceProvider,
  PlainLanguageCode,
  Gender,
  Privacy,
  UserType,
} from '../../../../api';
import {
  CenteredContainer,
  Loader,
  CRUDKey,
  If,
  LanguageSelect,
  AvatarUploader,
  KeySelect,
} from '../../../../common';
import {
  capitalizeFirstLetter,
  confirmDangerousAction,
  createNotification,
  NotificationType,
  RouteData,
  valuesToOptions,
} from '../../../../utils';
import { VoiceSelect } from './voice-select.component';
import { TestVoiceButton } from './test-voice-button.component';
import HandleFormItem from '../handle-form-item.component';
import { useInitStore } from '../../../../stores';

const { TextArea } = Input;
const { Title } = Typography;

const DEFAULT_PERSONALITY_TRAITS = [
  'Empathetic',
  'Diligent',
  'Shy',
  'Genuine',
  'Submissive',
  'Dominant',
  'Joyful',
  'Sevoted',
  'Energetic',
  'Supportive',
  'Friendly',
  'Kind',
  'Methodical',
  'Patient',
  'Trusting',
  'Abrasive',
  'Careless',
  'Compulsive',
  'Cynical',
  'Disobedient',
  'Egocentric',
  'Lazy',
  'Casual',
  'Obedient',
  'Reserved',
  'Predictable',
  'Surprising',
  'Ambitious',
  'Cold',
  'Imposing',
  'Bold',
  'Confident',
  'Determined',
  'Persuasive',
  'Affectionate',
  'Altruistic',
  'Playful',
  'Loving',
  'Smart',
  'Authoritative',
  'Strict',
  'Cute',
  'Respectful',
  'Independent',
  'Curious',
  'Sharp',
  'Flirtatious',
  'Loyal',
  'Nurturing',
  'Decisive',
  'Resilient',
  'Compassionate',
  'Artistic',
  'Mysterious',
  'Spirited',
  'Fiery',
  'Protective',
  'Insightful',
  'Graceful',
  'Fearless',
  'Adventurous',
  'Mischievous',
  'Charming',
  'Precise',
  'Resourceful',
  'Fierce',
  'Intelligent',
  'Charismatic',
  'Clever',
];

/*
const DEFAULT_HOBBIES = [
  'Hanging Out',
  'Sunbathing',
  'Cooking',
  'Cleaning',
  'Working Out',
  'Yoga',
  'Fitness',
  'Nature',
  'Beach',
  'Traveling',
  'Self Care',
  'Healing',
  'Fine Dining',
  'Fashion',
  'Reading',
  'Music',
  'Shopping',
  'Gambling',
  'Painting',
  'Modeling',
  'Animals',
  'Gardening',
  'Camping',
];

const BODY_TYPES = ['Slim', 'Fit', 'Muscular', 'Athletic', 'Voluptuous'];
*/

const DEFAULT_DIALOGUE_STYLE = [
  'Anxious',
  'Contemplative',
  'Curt',
  'Expressive',
  'Hilarious',
  'Inquisitive',
  'Intense',
  'Mischievous',
  'Snarky',
  'Sympathetic',
  'Talkative',
  'Unflinching',
  'Aggressive',
  'Cruel',
  'Defiant',
  'Creative',
  'Passionate',
  'Confident',
  'Optimistic',
  'Pessimistic',
  'Sneaky',
];

function tagsValidator(label: string) {
  return (rule: RuleObject, value: string[]) => {
    const regex = /^[A-Za-z\s]*$/;

    const invalidItems = value.filter((item) => !item.match(regex));

    if (invalidItems.length > 0) {
      return Promise.reject(
        new Error(
          `${capitalizeFirstLetter(
            label,
          )}s can only contain letters and spaces. Invalid ${label}${
            invalidItems.length > 1 ? 's' : ''
          }: ${invalidItems.join(', ')}`,
        ),
      );
    }

    return Promise.resolve();
  };
}

const Character: React.FC<{
  rawRoutes?: { [key: string]: RouteData[] };
}> = ({ rawRoutes }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const user = useInitStore((state) => state.user);

  const [gender, setGender] = useState<Gender>();
  const [language, setLanguage] = useState<PlainLanguageCode>();
  const [voiceProvider, setVoiceProvider] = useState<VoiceProvider>();
  const [voice, setVoice] = useState<string>();
  const [name, setName] = useState<string>();
  const [elevenLabsKey, setElevenLabsKey] = useState<string>();
  const [elevenLabsStability, setElevenLabsStability] = useState<number>();
  const [elevenLabsSimilarityBoost, setElevenLabsSimilarityBoost] =
    useState<number>();
  const [elevenLabsStyle, setElevenLabsStyle] = useState<number>();

  const back = () => navigate('/characters');

  const { request, requestKey, response, isLoading, error } = useRequests({
    requests: getCharacterRequests(params.characterId!),
    callbacks: {
      onSuccess: (data, key) => {
        if (key === CRUDKey.Read) {
          form.setFieldsValue(data);

          setGender(data.gender);
          setLanguage(data.language);
          setVoiceProvider(data.voiceProvider);
          setVoice(data.voice);
          setElevenLabsStability(data.elevenLabsStability);
          setElevenLabsSimilarityBoost(data.elevenLabsSimilarityBoost);
          setElevenLabsStyle(data.elevenLabsStyle);
          setElevenLabsKey(data.elevenLabsKey);
          setName(data.name);
        } else if (key === CRUDKey.Update) {
          createNotification({
            key: 'characterUpdated',
            message: 'Character saved successfully.',
            type: NotificationType.Success,
            duration: 6,
          });
        } else if (key === CRUDKey.Delete) {
          createNotification({
            key: 'characterDeleted',
            message: 'Character deleted successfully.',
            type: NotificationType.Success,
            duration: 6,
          });

          back();
        }
      },
      onError: formRequestErrorHandler(form),
    },
  });

  useEffect(() => {
    if (params.characterId) request.read();
  }, [params.characterId]);

  const sliderTooltipFormatter = (value?: number) => `${value || 0}%`;

  const viewMode = user?.type !== UserType.Admin;

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Loader
        error={requestKey === CRUDKey.Read ? error : undefined}
        isLoading={isLoading}
      >
        <Breadcrumb style={{ marginTop: '40px' }}>
          <Breadcrumb.Item>
            <Link to={`/characters`}>Characters</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>{response?.name || '...'}</Breadcrumb.Item>
        </Breadcrumb>

        <Form
          form={form}
          onFinish={request.update}
          initialValues={response}
          layout="vertical"
          requiredMark={false}
          disabled={viewMode}
        >
          <Row>
            <Space
              direction="vertical"
              style={{ width: '100%', marginBottom: '40px' }}
              size="small"
            >
              <Title style={{ marginBottom: 0 }}>{response?.name}</Title>

              <Space
                direction="horizontal"
                style={{ width: '100%', marginTop: '10px' }}
                wrap
              >
                <Button
                  disabled={isLoading}
                  size="large"
                  icon={<PictureOutlined />}
                  onClick={() =>
                    navigate(`/character/${params.characterId}/media`)
                  }
                >
                  Media
                </Button>

                <If condition={user?.type === UserType.Admin}>
                  <Button
                    disabled={isLoading}
                    size="large"
                    icon={<BranchesOutlined />}
                    onClick={() =>
                      navigate(`/character/${params.characterId}/fine-tune`)
                    }
                  >
                    Fine Tune
                  </Button>
                </If>

                <If condition={user?.type === UserType.Admin}>
                  <Button
                    disabled={isLoading}
                    size="large"
                    icon={<MessageOutlined />}
                    onClick={() =>
                      navigate(`/character/${params.characterId}/chat`)
                    }
                  >
                    Chat
                  </Button>
                </If>

                <If
                  condition={
                    user?.type === UserType.Admin ||
                    user?.type === UserType.Creator
                  }
                >
                  <Button
                    disabled={isLoading}
                    size="large"
                    icon={<LineChartOutlined />}
                    onClick={() =>
                      navigate(`/character/${params.characterId}/overview`)
                    }
                  >
                    Overview
                  </Button>
                </If>
              </Space>
            </Space>
          </Row>

          <Row align="top">
            <Col flex="200px" style={{ marginRight: '40px' }}>
              <Form.Item label="Avatar">
                <AvatarUploader
                  templateId={
                    process.env
                      .REACT_APP_CHARACTER_AVATAR_TRANSLOADIT_TEMPLATE_ID!
                  }
                  fields={{ character_id: response?.id }}
                  initialValue={response?.avatarUrl}
                  viewMode={viewMode}
                  size={260}
                />
              </Form.Item>

              <Form.Item label="Cover">
                <AvatarUploader
                  templateId={
                    process.env
                      .REACT_APP_CHARACTER_COVER_TRANSLOADIT_TEMPLATE_ID!
                  }
                  fields={{ character_id: response?.id }}
                  initialValue={response?.coverUrl}
                  viewMode={viewMode}
                  width={260}
                  height={325}
                />
              </Form.Item>
            </Col>

            <Col flex="1" style={{ minWidth: 'min(450px, 100%)' }}>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: 'Name is required' },
                    {
                      max: 30,
                      message: 'Name must be maximum 30 characters',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ex: Jane Doe"
                    onChange={(e) => setName(e.target.value)}
                    style={{ maxWidth: '500px' }}
                  />
                </Form.Item>

                <HandleFormItem rawRoutes={rawRoutes} maxWidth={500} />

                <If condition={!viewMode}>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[
                      {
                        max: 2200,
                        message: 'Role must be maximum 2000 characters',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 6, maxRows: 10 }}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="background"
                    label="Background"
                    rules={[
                      {
                        max: 2200,
                        message: 'Background must be maximum 2000 characters',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 6, maxRows: 10 }}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="bio"
                    label="Bio"
                    rules={[
                      {
                        max: 500,
                        message: 'Bio must be maximum 500 characters',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 6 }}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>

                  <Form.Item name="nsfw" label="NSFW" valuePropName="checked">
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    name="welcomeMessage"
                    label="Welcome Message"
                    rules={[
                      {
                        max: 1000,
                        message:
                          'Welcome message must be maximum 1000 characters',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="introMessage"
                    label="Intro Message"
                    rules={[
                      {
                        max: 1000,
                        message:
                          'Intro message must be maximum 1000 characters',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="privacy"
                    label="Privacy"
                    rules={[{ required: true, message: 'Privacy is required' }]}
                  >
                    <Radio.Group>
                      <Radio.Button value={Privacy.Private}>
                        Private
                      </Radio.Button>

                      <Radio.Button value={Privacy.Public}>Public</Radio.Button>

                      <Radio.Button value={Privacy.Unlisted}>
                        Unlisted
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{ required: true, message: 'Gender is required' }]}
                  >
                    <Radio.Group onChange={(e) => setGender(e.target.value)}>
                      <Radio.Button value={Gender.Male}>Male</Radio.Button>

                      <Radio.Button value={Gender.Female}>Female</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name="language"
                    label="Language"
                    rules={[
                      { required: true, message: 'Language is required' },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <LanguageSelect
                      style={{
                        width: '200px',
                        maxWidth: '100%',
                        height: '40px',
                      }}
                      onChange={(value: string) =>
                        setLanguage(value as PlainLanguageCode)
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="voiceProvider"
                    label="Voice Provider"
                    rules={[
                      { required: true, message: 'Voice Provider is required' },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => setVoiceProvider(e.target.value)}
                    >
                      <Radio.Button value="none">None</Radio.Button>

                      <Radio.Button value="amazonPolly">
                        Amazon Polly
                      </Radio.Button>

                      <Radio.Button value="elevenLabs">
                        Eleven Labs
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <If condition={voiceProvider !== VoiceProvider.None}>
                    <Space
                      direction="horizontal"
                      style={{ width: '100%' }}
                      wrap
                    >
                      <Form.Item
                        name="voice"
                        label="Voice"
                        rules={[
                          { required: true, message: 'Voice is required' },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <VoiceSelect
                          elevenLabsKey={elevenLabsKey}
                          voiceProvider={voiceProvider}
                          gender={gender}
                          language={language}
                          style={{
                            width: '200px',
                            maxWidth: '100%',
                            height: '40px',
                          }}
                          onChange={setVoice}
                        />
                      </Form.Item>

                      <TestVoiceButton
                        name={name!}
                        language={language!}
                        options={{
                          voiceId: voice!,
                          voiceProvider: voiceProvider!,
                          elevenLabsKey,
                          elevenLabsStability,
                          elevenLabsSimilarityBoost,
                          elevenLabsStyle,
                        }}
                        disabled={isLoading}
                      />
                    </Space>
                  </If>

                  <If condition={voiceProvider === VoiceProvider.ElevenLabs}>
                    <Form.Item
                      name="elevenLabsKey"
                      label="Eleven Labs API Key"
                      rules={[
                        {
                          required: true,
                          message: 'Eleven Labs API Key is required',
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <KeySelect
                        style={{
                          width: '200px',
                          maxWidth: '100%',
                          height: '40px',
                        }}
                        onChange={(value: string) => setElevenLabsKey(value)}
                      />
                    </Form.Item>

                    <Form.Item
                      name="elevenLabsStability"
                      label="Voice Stability"
                      style={{ width: '100%' }}
                    >
                      <Slider
                        onChange={setElevenLabsStability}
                        tooltip={{ formatter: sliderTooltipFormatter }}
                        style={{ maxWidth: '500px' }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="elevenLabsSimilarityBoost"
                      label="Voice Clarity + Similarity Enhancement"
                      style={{ width: '100%' }}
                    >
                      <Slider
                        onChange={setElevenLabsSimilarityBoost}
                        tooltip={{ formatter: sliderTooltipFormatter }}
                        style={{ maxWidth: '500px' }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="elevenLabsStyle"
                      label="Voice Style Exaggeration"
                      style={{ width: '100%' }}
                    >
                      <Slider
                        onChange={setElevenLabsStyle}
                        tooltip={{ formatter: sliderTooltipFormatter }}
                        style={{ maxWidth: '500px' }}
                      />
                    </Form.Item>
                  </If>

                  <Form.Item
                    name="personalityTraits"
                    label="Personality Traits"
                    rules={[{ validator: tagsValidator('personality trait') }]}
                  >
                    <Select
                      mode="tags"
                      allowClear
                      placeholder="Enter or select a personality trait"
                      options={valuesToOptions(DEFAULT_PERSONALITY_TRAITS)}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="dialogueStyle"
                    label="Dialogue Style"
                    rules={[{ validator: tagsValidator('dialogue style') }]}
                  >
                    <Select
                      mode="tags"
                      allowClear
                      placeholder="Enter or select a dialogue style"
                      options={valuesToOptions(DEFAULT_DIALOGUE_STYLE)}
                      style={{ maxWidth: '800px' }}
                    />
                  </Form.Item>
                </If>

                <If condition={!viewMode}>
                  <Space>
                    <Button
                      disabled={isLoading}
                      onClick={form.submit}
                      size="large"
                      type="primary"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>

                    <If condition={user?.type === UserType.Admin}>
                      <Button
                        disabled={isLoading}
                        onClick={() =>
                          confirmDangerousAction({
                            action: 'Delete',
                            name: 'this character',
                            onConfirm: request.delete,
                          })
                        }
                        size="large"
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                      >
                        Delete
                      </Button>
                    </If>
                  </Space>
                </If>
              </Space>
            </Col>
          </Row>
        </Form>
      </Loader>
    </CenteredContainer>
  );
};

export default Character;
