import { Avatar, Space, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FC } from 'react';

import { UserParams } from '../../../../api';

const { Text } = Typography;

const AccountInfo: FC<{ data?: UserParams }> = ({ data }) => {
  return (
    <Space
      direction="horizontal"
      align="center"
      size={10}
      style={{ margin: '10px' }}
    >
      <Avatar
        src={data?.avatarUrl}
        icon={!data?.avatarUrl && <UserOutlined />}
        size={40}
        style={{ marginBottom: '5px' }}
      />

      <Text>{data?.name}</Text>
    </Space>
  );
};

export default AccountInfo;
