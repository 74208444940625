import { Breadcrumb, Space, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';

import {
  CharacterParams,
  FineTuneExampleParams,
  ReadCharacterRequest,
  useAPIRequest,
  FineTuneExampleListRequest,
} from '../../../../api';
import { CRUDKey, CenteredContainer, Loader } from '../../../../common';
import FineTuneExample from './fine-tune-example.component';
import FineTunedModel from './fine-tuned-model.component';

const { Title } = Typography;

const FineTune: React.FC = () => {
  const params = useParams();

  const { response, setResponse, error, isLoading } = useAPIRequest<{
    data: FineTuneExampleParams[];
  }>(() => FineTuneExampleListRequest(params.characterId!));

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  const onInnerExampleSuccess = (data: FineTuneExampleParams, key: string) => {
    if (!response) return;

    if (key === CRUDKey.Create) {
      setResponse({ data: [...response.data, data] });
    } else if (key === CRUDKey.Update) {
      const newResponseData = response.data.concat();

      const index = newResponseData.findIndex((item) => item.id === data.id);

      if (index !== -1) {
        newResponseData[index] = data;

        setResponse({ data: newResponseData });
      }
    } else if (key === CRUDKey.Delete) {
      const newResponseData = response.data.concat();

      const index = newResponseData.findIndex((item) => item.id === data.id);

      if (index !== -1) {
        newResponseData.splice(index, 1);

        setResponse({ data: newResponseData });
      }
    }
  };

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Loader error={error} isLoading={isLoading}>
          <Breadcrumb style={{ marginTop: '40px' }}>
            <Breadcrumb.Item>
              <Link to={`/characters`}>Characters</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/character/${params.characterId}`}>
                {characterResponse?.name || '...'}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>Fine Tune</Breadcrumb.Item>
          </Breadcrumb>

          <Title
            style={{
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            Fine Tune
          </Title>

          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Space size="large" style={{ width: '100%' }} wrap>
              {response?.data?.map((item, index) => (
                <FineTuneExample
                  characterId={params.characterId!}
                  data={item}
                  index={index + 1}
                  key={item.id}
                  onSuccess={onInnerExampleSuccess}
                />
              ))}

              <FineTuneExample
                characterId={params.characterId!}
                onSuccess={onInnerExampleSuccess}
              />
            </Space>

            <FineTunedModel characterId={params.characterId!} />
          </Space>
        </Loader>
      </Space>
    </CenteredContainer>
  );
};

export default FineTune;
