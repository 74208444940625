import { Form, Row, Col, Space, Input, Select, Button, Typography } from 'antd';
import { countries } from 'countries-list';

import {
  formRequestErrorHandler,
  getUserRequests,
  useRequests,
} from '../../../../api';
import {
  CenteredContainer,
  If,
  Loader,
  CRUDKey,
  SelectAutoClose,
  AvatarUploader,
} from '../../../../common';
import { useInitStore } from '../../../../stores';
import { createNotification, NotificationType } from '../../../../utils';

const { Option } = Select;
const { Text, Title } = Typography;

const countriesNames = Object.entries(countries).map(
  ([, country]) => country.name,
);

const ProfileEditor: React.FC<{ onboarding?: boolean }> = ({ onboarding }) => {
  const [form] = Form.useForm();

  const setUser = useInitStore((state) => state.setUser);

  // default info is from GET request that has transform=true and not from profile in init data since avatar is not transformed there

  const { request, requestKey, response, isLoading, error } = useRequests({
    requests: getUserRequests(),
    callbacks: {
      onSuccess: (data, key) => {
        form.setFieldsValue(data);

        if (key === CRUDKey.Update) {
          createNotification({
            key,
            message: 'Changes saved successfully',
            type: NotificationType.Success,
          });

          setUser(data);
        }
      },
      onError: formRequestErrorHandler(form),
    },
  });

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Loader
        error={requestKey === CRUDKey.Read ? error : undefined}
        isLoading={isLoading}
      >
        <Form
          form={form}
          onFinish={request.update}
          layout="vertical"
          requiredMark={false}
        >
          <Row align="middle">
            <Col flex="200px" style={{ marginRight: '105px' }} />
            <Col flex="1">
              <Space
                direction="vertical"
                style={{ marginTop: '40px', marginBottom: '40px' }}
              >
                <If condition={!onboarding}>
                  <Title style={{ marginBottom: 0 }}>Profile</Title>
                </If>

                <If condition={onboarding}>
                  <Title style={{ marginBottom: 0 }}>Welcome to SWOONS!</Title>

                  <Text>Tell us a bit about yourself</Text>
                </If>
              </Space>
            </Col>
          </Row>

          <Row align="top">
            <Col flex="200px" style={{ marginRight: '40px' }}>
              <Form.Item label="Avatar">
                <AvatarUploader
                  templateId={
                    process.env.REACT_APP_USER_AVATAR_TRANSLOADIT_TEMPLATE_ID!
                  }
                  fields={{ user_id: response?.id }}
                  initialValue={response?.avatarUrl}
                />
              </Form.Item>
            </Col>

            <Col flex="1">
              <Row>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      { required: true, message: 'Name is required' },
                      {
                        max: 30,
                        message: 'Name must be maximum 30 characters',
                      },
                    ]}
                    style={{ maxWidth: '250px' }}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[{ required: true, message: 'Country is required' }]}
                    style={{ maxWidth: '400px' }}
                  >
                    <SelectAutoClose placeholder="Select country" showSearch>
                      {countriesNames.map((country) => (
                        <Option value={country} key={country}>
                          {country}
                        </Option>
                      ))}
                    </SelectAutoClose>
                  </Form.Item>
                </Space>
              </Row>

              <Row>
                <Button
                  disabled={isLoading}
                  onClick={form.submit}
                  type="primary"
                >
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Loader>
    </CenteredContainer>
  );
};

export default ProfileEditor;
