import { AxiosRequestConfig } from 'axios';

import { BillingPeriod } from '../billing';

export const CharacterBillingOverviewRequest = (
  characterId: string,
  period: BillingPeriod,
): AxiosRequestConfig => {
  return {
    url: 'v1/billing/overview/character',
    method: 'get',
    params: { characterId, period },
  };
};
