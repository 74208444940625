import { Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const MediaItemMssing: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Space
      direction="horizontal"
      align="center"
      size={2}
      style={{
        justifyContent: 'center',
        backgroundColor: '#000000CC',
        padding: '5px 10px',
        borderRadius: '10px',
      }}
    >
      <CloseOutlined
        style={{
          fontSize: '10px',
          color: 'red',
          position: 'relative',
        }}
      />

      <span style={{ fontSize: '12px', color: 'red' }}>{text}</span>
    </Space>
  );
};

export default MediaItemMssing;
