import { AxiosRequestConfig } from 'axios';

import { BillingPeriod } from './billing-period.enum';

export const BillingOverviewRequest = (
  period: BillingPeriod,
): AxiosRequestConfig => {
  return {
    url: 'v1/billing/overview',
    method: 'get',
    params: { period },
  };
};
