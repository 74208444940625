export const MailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1137_1463)">
      <path
        d="M2.1875 3.29492H18.8125C18.8125 3.29492 20.5625 3.29492 20.5625 4.96159V14.9616C20.5625 14.9616 20.5625 16.6283 18.8125 16.6283H2.1875C2.1875 16.6283 0.4375 16.6283 0.4375 14.9616V4.96159C0.4375 4.96159 0.4375 3.29492 2.1875 3.29492Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0471 3.77979L10.5 11.2115L0.952875 3.77979"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1137_1463">
        <rect width="21" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
