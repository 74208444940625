export const PlaySvg = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_789_4454)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2 5.49997L2.5 0.199971C1.4 -0.400029 0 0.399971 0 1.69997V12.2C0 13.5 1.4 14.4 2.5 13.7L11.1 8.39997C12.3 7.79997 12.3 6.19997 11.2 5.49997ZM10.4 7.19997L1.8 12.5C1.6 12.6 1.4 12.5 1.4 12.3V1.69997C1.4 1.49997 1.6 1.39997 1.8 1.49997L10.4 6.79997C10.6 6.89997 10.6 7.09997 10.4 7.19997Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_789_4454">
        <rect width="12" height="14" />
      </clipPath>
    </defs>
  </svg>
);

export const PauseSvg = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_789_4450)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.8 0C0.4 0 0 0.3 0 0.7V11.4C0 11.7 0.4 12 0.8 12C1.2 12 1.6 11.7 1.6 11.3V0.7C1.6 0.3 1.2 0 0.8 0Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20002 0C8.80002 0 8.40002 0.3 8.40002 0.7V11.4C8.40002 11.8 8.80002 12.1 9.20002 12.1C9.60002 12.1 10 11.8 10 11.4V0.7C10 0.3 9.60002 0 9.20002 0Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_789_4450">
        <rect width="10" height="12" />
      </clipPath>
    </defs>
  </svg>
);

export const MessageSpinnerSvg = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'white' }}
  >
    <circle className="spinner_b2T7" cx="4" cy="12" r="3" />
    <circle className="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3" />
    <circle className="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3" />
  </svg>
);
