import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { CreateFineTuneExampleRequest } from './create-fine-tune-example.request';
import { DeleteFineTuneExampleRequest } from './delete-fine-tune-example.request';
import { FineTuneExampleParams } from './fine-tune-example-params.interface';
import { ReadFineTuneExampleRequest } from './read-fine-tune-example.request';
import { UpdateFineTuneExampleRequest } from './update-fine-tune-example.request';

export function getFineTuneExampleRequests(
  characterId: string,
): Requests<FineTuneExampleParams> {
  return {
    [CRUDKey.Create]: (params) =>
      CreateFineTuneExampleRequest(characterId, params),
    [CRUDKey.Read]: (id) => ReadFineTuneExampleRequest(id),
    [CRUDKey.Update]: (id, params) => UpdateFineTuneExampleRequest(id, params),
    [CRUDKey.Delete]: (id) => DeleteFineTuneExampleRequest(id),
  };
}
