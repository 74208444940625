import { RouteData } from '../../utils';
import { SettingsRoutes } from './settings/settings.routes';
import { OverviewRoutes } from './overview/overview.routes';
import { BillingRoutes } from './billing/biilling.routes';

export const AppRoutes: RouteData[] = [
  ...SettingsRoutes,
  ...OverviewRoutes,
  ...BillingRoutes,
];
