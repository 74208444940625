import { AxiosRequestConfig } from 'axios';

export interface SignInParams {
  email: string;
  password: string;
}

export const SignInRequest = (params: SignInParams): AxiosRequestConfig => {
  return {
    url: 'v1/user/signin',
    method: 'post',
    data: params,
  };
};
