import { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';

import { useAsyncPipeline } from '../hooks';
import { AsyncPipelineItem, AsyncPipelineOptions } from '../interfaces';

export const AsyncPipelineLoader: FC<{
  pipeline: AsyncPipelineItem[];
  options?: AsyncPipelineOptions;
}> = ({ pipeline, options }) => {
  const { error } = useAsyncPipeline(pipeline, options);

  if (error) return <div style={{ color: red.primary }}>Error: {error}</div>;

  return <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />;
};
