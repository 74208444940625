import { red } from '@ant-design/colors';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { If } from './if.component';

export const Loader: FC<
  PropsWithChildren<{
    isLoading?: boolean;
    error?: string;
    style?: React.CSSProperties;
  }>
> = ({ isLoading = false, error, style, children }) => {
  return (
    <>
      <If condition={error}>
        <span style={{ color: red[3], ...style }}>{error}</span>
      </If>

      <If condition={!error}>
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={isLoading}
          style={style}
        >
          {children}
        </Spin>
      </If>
    </>
  );
};
