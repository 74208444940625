import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { CharacterParams } from './character-params.interface';
import { DeleteCharacterRequest } from './delete-character.request';
import { ReadCharacterRequest } from './read-character.request';
import { UpdateCharacterRequest } from './update-character.request';

export function getCharacterRequests(id: string): Requests<CharacterParams> {
  return {
    [CRUDKey.Read]: () => ReadCharacterRequest(id),
    [CRUDKey.Update]: (params) => UpdateCharacterRequest(id, params),
    [CRUDKey.Delete]: () => DeleteCharacterRequest(id),
  };
}
