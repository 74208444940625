import { useRef } from 'react';
import { Buffer } from 'buffer';
import {
  AudioContext,
  IAudioBufferSourceNode,
  IAudioContext,
  IGainNode,
} from 'standardized-audio-context';

export const useBufferedVoice = () => {
  const audioContextRef = useRef<AudioContext>();
  const gainNode = useRef<IGainNode<IAudioContext>>();

  const getAudioContext = (): AudioContext => {
    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext();

      gainNode.current = audioContextRef.current.createGain();

      gainNode.current.gain.value = 1;

      gainNode.current.connect(audioContextRef.current.destination);
    }

    return audioContextRef.current;
  };

  const createAudioSource = async (
    base64: string,
  ): Promise<IAudioBufferSourceNode<IAudioContext>> => {
    const uint8array = Buffer.from(base64, 'base64');

    const audioContext = getAudioContext();

    const audioBuffer: AudioBuffer = await audioContext.decodeAudioData(
      uint8array.buffer,
    );

    const source: IAudioBufferSourceNode<IAudioContext> =
      audioContext.createBufferSource();

    source.connect(gainNode.current!);

    source.buffer = audioBuffer;

    return source;
  };

  const createVoice = async (base64: string): Promise<() => Promise<void>> => {
    const source = await createAudioSource(base64);

    return () => {
      return new Promise<void>((resolve) => {
        source.addEventListener('ended', () => resolve());

        source.start(0);
      });
    };
  };

  const playVoice = async (base64: string): Promise<void> => {
    const play = await createVoice(base64);

    return play();
  };

  return playVoice;
};
