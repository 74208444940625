import { AxiosRequestConfig } from 'axios';

import { Message } from './message.interface';

export const ChatRequest = (id: string): AxiosRequestConfig => {
  return {
    url: `v1/chat/${id}`,
    method: 'get',
    transformResponse: (response: string) => {
      const { messages, ...data }: { messages: Message[] } =
        JSON.parse(response);

      return {
        ...data,
        messages: messages.map((message) => ({
          ...message,
          sent: new Date(message.sent),
        })),
      };
    },
  };
};
